import { ArriendosService } from "@/service/services/Arriendos.service.";
import {
    Alert,
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TableData } from "@/components/common/Table";
import { productService } from "@/service/services/Product.service";

const header = [
    { name: 'codigo', label: 'Código', width: '' },
    { name: 'nombre', label: 'Nombre', width: '' },
    //  { name: 'precio', label: 'Precio', width: '', money:true },
    // { name: 'stock', label: 'Stock', width: '',integer:true }
];
const dataAction = [
    { name: 'select', color: 'success' },
]

export const DrawerDetailService: React.FC<any> = (props): JSX.Element => {
    const { openDrawer, setOpenDrawer, data } = props;

    const history = useHistory();
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })

    const [isRelation, setIsRelation] = useState<Boolean>(false)
    const [productSelected, setProductSelected] = useState<any>(null)
    const [dataRelation, setDataRelation] = useState<any>(null)

    const [search, setSearch] = useState<any>('');
    const [perPageData, setPerPageData] = useState<number>(5);
    const [pageData, setPageData] = useState<number>(1)
    const [Total, setTotal] = useState<any>(0)
    const [dataProduct, setDataProduct] = useState<any>([])

    useEffect(()=>{
        getRelation();
    },[data])

    useEffect(() => {
        try {
            getProduct();
        } catch (e) {
            console.log(e)
        }
    }, [pageData, perPageData])

    async function getProduct() {
        const resp = await productService.getProduct(perPageData, pageData)
        setDataProduct(resp.data.products.data);
        setTotal(resp.data.products.total)
    }
    
    const getRelation = async ( ) => {
        const response = await ArriendosService.getRelationProductsService(data.id);
        if (!!response?.status) {
            setIsRelation(response?.data.isRelation)
            setDataRelation(response?.data)
        } 
    }

    const PerPage = async (data) => {
        setPerPageData(data)
        setPageData(0)
    }

    const page = async (data) => {
        setPageData(data + 1)
    }

    const getSearchProduct = async (search) => {
        try {
            const resp = await productService.getSearchProduct(search);
            setDataProduct(resp.data.products);
        } catch (e) {
            console.log(e)
        }
    };
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const name = event.target.name;
            const value = event.target.value;
            switch (name) {
                case 'search':
                    if (value.length > 3) {
                        getSearchProduct(value)
                    }
                    if (value.length == 0) {
                        getProduct();
                    }
                    setSearch(value);

                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e)
        }
    };

    const recuperarData = (data) => {
        setProductSelected(data)
    }

    const saveRelation = async () => {
        const payload = {
            id_product: productSelected.id,
            id_service: data.id,
        }

        const response = await ArriendosService.postRelationProductsService(payload);
        if (!!response?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })
            setProductSelected(null)
            await getRelation()
        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
    }

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    {data?.nombre}
                </Typography>
                {/* <Typography mb={3}>{data?.nombre}</Typography> */}

                {
                    !!isRelation ?
                        <>
                            
                            {/* INFO DEL INVENTARIO DEL PRODUCTO */}
                            <Typography variant="h5" my={2}>Producto relacionado </Typography>
                            <Typography>Código: {dataRelation?.product?.codigo}</Typography>
                            <Typography>Nombre: {dataRelation?.product?.nombre}</Typography>

                            {/* ARRIENDOS DEL PRODUCTO */}

                        </>
                        :
                        <>
                            {
                                !!productSelected ?
                                    <>
                                        <Typography variant="h5" my={2}>Producto seleccionado:</Typography>
                                        <Typography>Código: {productSelected.codigo}</Typography>
                                        <Typography>Nombre: {productSelected.nombre}</Typography>

                                        <div style={{ display: 'flex', gap: 1 }}>
                                            <Button variant="contained" color='warning' sx={{ my: 2 }} onClick={() => setProductSelected(null)}>Cancelar</Button>
                                            <Button variant="contained" sx={{ my: 2 }} onClick={() => saveRelation()}>Guardar relación</Button>
                                        </div>
                                    </> :
                                    <>
                                        <Typography>El servicio no está relacionado a un producto, por favor realice la selección</Typography>
                                        <Grid container spacing={1} >
                                            <Grid item xs={12}>
                                                {/* <InputLabel >Filtrar Resultados</InputLabel> */}
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="search"
                                                    value={search}
                                                    onChange={handleInput}
                                                    placeholder="Introduce un Nombre o Código Producto"
                                                    type="search"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    size="small"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ mt: 1 }}>
                                            <TableData
                                                header={header}
                                                data={dataProduct ? dataProduct : []}
                                                action={dataAction}
                                                RecuperarData={recuperarData}
                                                total={Total ? Total : 0}
                                                disabledTextAction={true}
                                                perPage={PerPage}
                                                page={page}
                                            />
                                        </Grid>
                                    </>
                            }

                        </>
                }

                <Typography sx={{ mb: 3 }}></Typography>
                <Typography></Typography>
                <Typography></Typography>
                <Typography></Typography>

                <Typography></Typography>
                <div style={{ padding: '10px' }}>
                    {/* <Button variant="contained" fullWidth onClick={()=>setOpenAsigned(true)}>Asignar</Button> */}

                </div>





                <div style={{ width: '400px', margin: '0 auto', textAlign: 'center', marginTop: '40px', padding: '20px' }}>



                    <div style={{ padding: '10px' }}>


                    </div>

                </div>

            </Box>
            {

            }
        </Drawer>

    );
};
