import { ArriendosService } from "@/service/services/Arriendos.service.";
import { Alert, Box, Button, Drawer, FormControl, Snackbar, TextField, Typography } from "@mui/material"
import { useState } from "react";

export const DrawerDetallePagoProductos: React.FC<any> = (props): JSX.Element => {

    const { openDrawer, setOpenDrawer, itemSelected, getArriendo, data } = props;
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [paymentImage, setPaymentImage] = useState(null);
    const [codePay, setCodePay] = useState(null)

    const confirmarPago = async() => {
        
        const formData = new FormData();
        formData.append('codigo', itemSelected.cod_cobranza );
        formData.append('img_pago',paymentImage );
        formData.append('cod_pago',codePay );

        const registerArriendo = await ArriendosService.pagarMensualidad(formData);
        if (!!registerArriendo?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'success' })
            await getArriendo(data?.id);
            setOpenDrawer(false)
        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'error' })
        }

    }

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    Detalle de Pago
                </Typography>

                <div style={{
                    borderBottom: '3px solid #155172', // Línea inferior gruesa
                    borderTop: '3px solid #155172', // Línea superior gruesa solo para el primer elemento
                    padding: '10px',
                    marginBottom: '10px',
                    height: '400px',
                    overflowY: 'auto',
                    margin: '0 auto', // Centrar el contenedor en el drawer
                }}>
                    {itemSelected.detalle.map((item, index) => (
                        <div key={item.id} style={{
                            borderBottom: index < itemSelected.detalle.length - 1 ? '1px solid #ddd' : 'none', // Línea delgada entre elementos
                            paddingBottom: '10px',
                            paddingTop: '5px',
                            marginBottom: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}>
                            <>
                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                    {`(${item?.servicio?.codigo || item?.producto?.codigo}) ${item?.servicio?.nombre || item?.producto?.nombre}`}
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {`${item?.cantidad} productos`}
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {item?.servicio &&
                                        (itemSelected.status_name === "Pagado"
                                            ? `${itemSelected.total_dias} día(s) pagado`
                                            : `${itemSelected.total_dias} día(s) pendientes de pago`
                                        )
                                    }
                                </Typography>


                                <Typography sx={{ color: '#000' }}>
                                    {` $ ${item?.monto_xcantidad} CLP`}
                                </Typography>

                            </>
                        </div>
                    ))}
                </div>

                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000', marginTop: "20px", marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${itemSelected.monto_neto} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000',marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>IVA</strong>
                    {` $ ${itemSelected.iva} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#000',marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${itemSelected.monto_total} CLP`}
                </Typography>
                {
                    itemSelected?.status_name != 'Pagado' 
                    && 
                    <>
                      <Typography sx={{ color: '#333', marginBottom: "10px" }}>
                    De manera opcional puede adjuntar un número de pago e imagen.
                </Typography>
                <input type="file" accept="image/*" 
                    onChange={(e) => { setPaymentImage(e.target.files[0])}}
                />

                <FormControl sx={{ marginTop: "10px" }}>
                    <TextField
                        id="cod_pago"
                        type="text"
                        multiline
                        name="cod_pago"
                        fullWidth
                        size="small"
                        onChange={(e)=>setCodePay(e.target.value)}
                        placeholder="Ingrese el número de pago como referencia"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        marginTop: "20px",
                        backgroundColor: "#155172",
                        color: "white",
                        padding: '20px',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                        }
                    }}
                    onClick={() => confirmarPago()}
                >
                    Pagar
                </Button>
                    </>
                }
              

            </Box>
        </Drawer>
    )
}