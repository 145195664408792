import React, { useEffect, useState } from 'react'
// import { useEffect, useState } from 'react'
import { ConfirmDialog } from '@/components/common/DialogConfirm'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import BlockIcon from '@mui/icons-material/Block'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import {
   Alert,
   Autocomplete,
   Card,
   CardContent,
   Checkbox,
   Divider,
   FormControlLabel,
   FormGroup,
   Grid,
   IconButton,
   Snackbar,
   TextField,
   Tooltip,
   Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { campaingService } from '@/service/services/Campaing.service'
type Campana = {
   id: number,
   idempresa: number,
   idregla: number | null,
   nombre: string,
   status: string,
   tipo: string,
}
type SnackBar = {
   open: boolean,
   severity: any,
   message: string,
   autoHideDuration: number,
}

type ModalProps = {
   open: boolean,
   setOpen: (boolean: boolean) => void,
   selectChat: any,
   renderChats: number,
   handleParticipantChat: () => Promise<void>,
   handleCloseChat: () => Promise<void>,
   setOpenModal: (boolean: boolean) => void
}

export const DrawerList = (props: ModalProps) => {
   const { open, setOpen, selectChat, renderChats, handleCloseChat, handleParticipantChat, setOpenModal } = props;
   const [error, setError] = useState(null);
   const [selectCampana, setSelectCamapana] = useState<Campana>({
      id: 0,
      idempresa: 0,
      idregla: 0,
      nombre: '',
      status: '',
      tipo: '',
   });
   const [dataCampaign, setDataCampaign] = useState<Campana[]>([]);
   const [snackBarConfig, setSnackBarConfig] = useState<SnackBar>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })
   const toggleDrawer =
      (open: boolean) => (_: React.KeyboardEvent | React.MouseEvent) => {
         setOpen(open)
      }

   useEffect(() => {
      getGeneralCampaign();
   }, [])


   async function getGeneralCampaign() {
      const resp = await campaingService.getGeneralCampaign(JSON.stringify([3]), '')
      console.log('resp', resp)
      if (resp?.code === 200) {
         setDataCampaign(resp.data ? resp.data?.detail : []);
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   async function saveContactGeneral() {
      if (!selectCampana?.id) {
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'la campaña es requerida',
         }));
      }
      if (!selectChat?.idClient) {
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'el prospecto es requerido',
         }));
      }
      const resp = await campaingService.saveContactGeneral({
         idcampana: selectCampana?.id,
         type: selectCampana?.tipo,
         idcliente: selectChat?.idClient,
      })
      if (resp?.code === 200) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }


   const handleSelect = (campaign) => {
      setSelectCamapana(campaign)
   }

   const onRedirec = () => {
      if (!selectCampana?.id) {
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'la campaña es requerida',
         }));
      }
      const newTabUrl = `/campaña/generales/${selectCampana?.id}?state=${false};`;
      window.open(newTabUrl, '_blank');
   }


   return (
      <div>
         <Drawer
            anchor={'right'}
            open={open}
            onClose={toggleDrawer(false)}
         >
            <Box
               sx={{
                  width: 500,
                  marginTop: '30px',
                  marginBottom: '30px',
                  textAlign: 'center',
               }}
            >

               <Card>
                  <CardContent>

                     <Grid container spacing={2} justifyContent={'start'}>
                        <Grid item xs={12}>
                           <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>Datos del chat</Typography>
                        </Grid>
                        {selectChat?.nameRzClient && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>Nombre Prospecto: {selectChat?.nameRzClient}</Typography>
                        </Grid>}
                        {selectChat?.rutClient && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>Rut Prospecto: {selectChat?.rutClient}</Typography>
                        </Grid>}
                        {selectChat?.nameContact && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>Nombre Contacto: {selectChat?.nameContact}</Typography>
                        </Grid>}
                        {selectChat?.ws_p_telefono && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>celular Contacto: {selectChat?.ws_p_telefono}</Typography>
                        </Grid>}
                     </Grid>
                  </CardContent>
               </Card>
               <Card>
                  <CardContent>
                     <Divider />
                     <Divider />
                     <Grid container spacing={2} justifyContent={'start'}>
                     {renderChats === 1 && selectChat.id > 0 && <Grid item container direction="row" xs={12} alignItems={'center'}>
                           <TaskAltIcon color='info' />
                           <Typography variant='body1' sx={{ textTransform: 'uppercase', textAlign: 'left' }} >{`Cerrar chat`}</Typography>
                        </Grid>}

                        {renderChats === 1 && selectChat.id > 0 && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }} >{`Horas vigente: ${selectChat?.diffHrs}/24 horas`}</Typography>
                        </Grid>}

                        {renderChats === 1 && selectChat.id > 0 && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }} >{`puedes cerrar el chat en cualquier momento, con tan solo un click`}</Typography>
                        </Grid>}
                        {renderChats === 1 && selectChat.id > 0 &&<Grid item container direction="row" xs={12} alignItems={'center'}>
                           <ErrorIcon color='error' />
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left', color: 'red' }} >{`despues de 24 horas el chat ya no estará disponible`}</Typography>
                        </Grid>}
                        {renderChats === 1 && selectChat.id > 0 && <Grid item xs={12} >
                           <Button variant='outlined' onClick={() => { handleCloseChat() }} disabled={selectChat?.id === 0 ? true: false} >{`Cerrar chat`}</Button>
                        </Grid>}
                        {/* {renderChats === 1 && selectChat.id > 0 && selectChat?.idClient === null && <Grid item xs={12} >
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }} >{`Guarda el contacto para luego ser parte de kyte`}</Typography>
                        </Grid>}
                        {renderChats === 1 && selectChat.id > 0 && selectChat?.idClient === null && <Grid item xs={12} >
                           <Button variant='outlined' onClick={() => { setOpenModal(true) }}>Guardar Contacto</Button>
                        </Grid>} */}
                     </Grid>
                  </CardContent>
               </Card>

             {renderChats === 1 && selectChat.id > 0 &&  <Card>
                  <CardContent>
                     <Divider />
                     <Divider />
                     <Grid container spacing={2} justifyContent={'start'}>
                        <Grid item container direction="row" xs={12} alignItems={'center'}>
                           <TaskAltIcon color='info' />
                           <Typography variant='body1' sx={{ textTransform: 'uppercase', textAlign: 'left' }} >{`Agregue el prospecto a una campaña`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>Seleccione Campaña</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Autocomplete
                              autoComplete
                              sx={{ bgcolor: '#fff' }}
                              size='small'
                              includeInputInList
                              value={selectCampana}
                              onChange={(_, campaign) => {
                                 handleSelect(campaign)
                              }}
                              id="campaign"
                              options={dataCampaign}
                              getOptionLabel={(option) => option.nombre}
                              renderInput={(params) => <TextField {...params} label="Campaña" error={error === "campaign" ? true : false} helperText={error === "campaign" ? "Campaign es un campo requerido" : ""} />}
                           />
                        </Grid>
                        {selectCampana?.id>0 && <Grid item xs={12}>
                           <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>Datos de la campaña</Typography>
                        </Grid>}
                        {selectCampana?.nombre && <Grid item xs={12}>
                           <Typography variant='body2' sx={{ textTransform: 'uppercase', textAlign: 'left' }}>Nombre: {selectCampana?.nombre}</Typography>
                        </Grid>}
                        {selectCampana?.id>0 && <Grid item xs={12}>
                           <Typography sx={{ textDecoration: 'underline', textTransform: 'uppercase', textAlign: 'left'  }} onClick={() => { onRedirec() }}>Ir a la campaña</Typography>
                        </Grid>}
                        <Grid item xs={12} >
                           <Button variant='outlined' onClick={() => { saveContactGeneral() }} disabled={selectCampana?.id === 0 ? true: false}>Agregar Prospecto A LA CAMPAÑA</Button>
                        </Grid>
                     </Grid>
                  </CardContent>
               </Card>}
            </Box>
         </Drawer>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </div>
   )
}
