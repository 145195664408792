import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, Drawer, FormControl, FormLabel, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { ModalGarantiaArriendo } from "../../ModalGarantiaArriendo/ModalGarantiaArriendo";
import { DrawerDevolucionProductos } from "./DrawerDevolucionProductos";
import { DrawerGarantia } from "./DrawerGarantia";

export const DetalleServicios: React.FC<any> = (props): JSX.Element => {

  const { dataArriendo, getArriendo, data } = props
  const [itemsSelected, setItemsSelected] = useState<any>([])
  const [openDrawerGarantia, setOpenDrawerGarantia] = useState<boolean>(false)
  const [dataGarantia, setDataGarantia] = useState<any>(null)
  const [openDrawerProductos, setOpenDrawerProductos] = useState<any>(false)

  const visualizarGarantia = () => {
    setOpenDrawerGarantia(true)
  }

  const abrirDrawerDevolucion = (item) => {
    setItemsSelected([item])
    setOpenDrawerProductos(true)
  }


  const seleccionarTodo = () => {
    if (itemsSelected.length === dataArriendo?.arriendoProductos?.length) {
      // Si todos están seleccionados, deseleccionar todos
      setItemsSelected([]);
    } else {
      // De lo contrario, seleccionar todos
      setItemsSelected(dataArriendo?.arriendoProductos.filter(item => !!item.servicio_devolucion));
    }
  };

  const toggleSeleccion = (item) => {
    if (itemsSelected.includes(item)) {
      // Si ya está seleccionado, deseleccionar
      setItemsSelected(itemsSelected.filter(itemselected => itemselected.id !== item.id));
    } else {
      // De lo contrario, seleccionar
      setItemsSelected([...itemsSelected, item]);
    }
  };

  const accionParaElementosSeleccionados = () => {
    setOpenDrawerProductos(true)
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div>
          <Checkbox
            checked={itemsSelected.length === dataArriendo?.arriendoProductos?.length}
            onChange={seleccionarTodo}
          />
          <Button
            variant="outlined"
            color="primary"
            disabled={itemsSelected.length === 0}
            onClick={accionParaElementosSeleccionados}
            style={{ marginLeft: '10px' }}
          >
            Devolver productos seleccionados
          </Button>
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => visualizarGarantia()}
        >
          Ver detalle de Garantia
        </Button>
      </div>


      {/* Resto del contenido con el map */}
      <div style={{ minHeight: "300px", marginTop: "20px", overflowY: 'scroll', maxHeight: '400px' }}>
        <Typography variant="h4" sx={{ color: "#155172", fontWeight: "bold" }}>
          Datos de los Servicios
        </Typography>
        {(dataArriendo?.arriendoProductos || []).map((item: any) => (
          <Card key={item.id} style={{ marginBottom: "10px" }}>
            <CardHeader title={item?.servicio?.nombre || ""} subheader={` Precio diario: $ ${item?.precio_dia} | Precio mensual: $ ${item?.precio_mensual} | Observacion: ${item?.observacion_admin || 'Sin obs'} `} />
            <Divider />
            <CardContent>
              <Typography>
                <strong>Producto:</strong> {`(${item?.producto?.codigo}) ${item?.producto?.nombre}`}
              </Typography>
              <Typography>
                <strong>Cantidad:</strong> {item?.cantidad_actual}
              </Typography>
              {
                item?.tipo_relacion == '3' &&
                <>
                  <Typography>
                    <strong>Dias transcurridos:</strong> {`${item?.dias_transcurridos} dias`}

                  </Typography>
                  <Typography>
                    <strong>Deuda actual:</strong> {` $ ${item?.deuda_actual} `}

                  </Typography>

                </>
              }
            </CardContent>
            <CardActions>
              {/* Agrega checkbox por cada elemento y maneja la selección */}
              {
                !!item?.servicio_devolucion ?
                <>
                  <Checkbox
                    checked={itemsSelected.includes(item)}
                    onChange={() => toggleSeleccion(item)}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => abrirDrawerDevolucion(item)}
                  >
                    Marcar Devolución
                  </Button>
                </>:
                <>
                <Typography>No se encontro el servicio devolucion para este producto, puede relacionarlo desde el mantenedor de servicios.</Typography>
                </>
              }
             
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => visualizarGarantia({ ...item?.garantia, garantia_status_name: item?.garantia_status_name })}
              >
                Ver Pago de Garantía
              </Button> */}
            </CardActions>
          </Card>
        ))}
      </div>
      {
        openDrawerGarantia &&
        <DrawerGarantia
          openDrawer={openDrawerGarantia}
          setOpenDrawer={setOpenDrawerGarantia}
          getArriendo={getArriendo}
          data={dataArriendo}
        />
      }

      {!!openDrawerProductos && <DrawerDevolucionProductos
        openDrawer={openDrawerProductos}
        setOpenDrawer={setOpenDrawerProductos}
        itemsSelected={itemsSelected}
        getArriendo={getArriendo}
        data={data}
      />
      }
    </>
  )
}