import React, { useEffect, useState } from "react";
import "./chatList.css";
import ChatListItems from "./ChatListItems";
import { Backdrop, Button, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
interface ChatUser {
   id: number,
   id_ws_prospect?: number,
   ws_p_telefono: string,
   idchat_type?: number,
   chatTypeName?: string,
   diffHrs?: string | null,
   status?: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

interface ChatListProps {
   chats: ChatUser[];
   renderChats: number;
   handleRenderChats: (id: number) => void;
   handleClickChat: (chat: any) => Promise<void>;
}

const ChatList: React.FC<ChatListProps> = ({
   chats,
   renderChats,
   handleRenderChats,
   handleClickChat
}) => {

   const handleTemplateWs = () => {
      const newTabUrl = `/template-ws`;
      window.open(newTabUrl, '_blank');
   }

   return (
      <div className="main__chatlist">
         <div className="chatlist__heading">
            <Grid item xs={12}>
               <Button fullWidth onClick={handleTemplateWs} startIcon={<AddIcon />} variant="outlined">
                  <span>MIS PLANTILLAS</span>
               </Button>      </Grid>
         </div>
         <div className="chatlist__heading">
            <Grid item xs={6} >
               <Button fullWidth onClick={() => { handleRenderChats(0) }} variant={renderChats === 0 ? 'contained' : 'text'}>PROSPECTOS</Button>
            </Grid>
            <Grid item xs={5} >
               <Button fullWidth onClick={() => { handleRenderChats(1) }} variant={renderChats === 1 ? 'contained' : 'text'}>CHAT</Button>
            </Grid>
         </div>
     <div className="chatlist__items">
            {chats.length > 0 && chats.map((item, index) => (
               <ChatListItems
                  name={item?.nameRzClient}
                  phone={item?.ws_p_telefono}
                  key={item.id}
                  animationDelay={index + 1}
                  active={"active"}
                  isOnline={true}
                  chat={item}
                  handleClickChat={handleClickChat}
               />
            ))}
         </div>
      </div>
   );
};

export default ChatList;
