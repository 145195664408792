import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const TemplateWsRepository = {

   getTemplateWs: async (term: string) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/ws-template/list-ws?idempresa=${id_empresa}&term=${term}`);
      return resp;
   },

   getTemplateWsOnVariable: async (onVariable: string) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/ws-template/list-ws?idempresa=${id_empresa}&notVariable=${onVariable}`);
      return resp;
   },

   createTemplate: async (data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/ws-template`, {
         ...data,
         idusuario: id_usuario
      });
      return resp;
   },

   SendTemplate: async (data) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/ws-template/send`, data);
      return resp;
   },

   getUrlTemplate: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/ws-template/config-url?id_empresa=${id_empresa}`);
      return resp;
   },

   syncTemplate: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/ws-template/sync`, {
         idempresa: id_empresa
      });
      return resp;
   },


}

