import { ChatsWsProspectView as ChatsWsProspect } from './ChatsWsProspect';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetChats } from '@/redux/actions';

// const mapStateToProps = (state) => ({...state})

const mapStateToProps = ({  ChatsReducer }) => ({
   $store: {
      ChatsReducer
   },
});
const mapDispatchToProps = (dispatch) => ({
   $action: bindActionCreators(
      {
         actionSetChats: actionSetChats
      },
      dispatch
   ),
});


export const ChatsWsProspectView: any = compose(
   withRouter,
   connect(mapStateToProps, mapDispatchToProps)
)(ChatsWsProspect);
