import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './ChatsWsProspect.type';
import { Alert, Box, Button, Grid, IconButton, InputBase, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { chatService } from '@service/services/Chat.service';
import ChatItem from './components/ChatItem';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import '@assets/styles/kyte-app.css';
import '@assets/styles/kyte-app.scss';
import SendIcon from '@mui/icons-material/Send';
import { ModalProspectoRapidoWS } from '@/components/common/Modal/ModalProspectoRapidoWS';
import ImageIcon from '@mui/icons-material/Image';
import ModalUploadImage from './components/ModalUploadImage';
import MenuIcon from '@mui/icons-material/Menu';
import { DrawerList } from './components/DrawerList';
import ChatList from './components/chatList/ChatList';
import ChatContent from './components/chatContent/ChatContent';
import UserProfile from './components/userProfile/UserProfile';
import "./ChatsWsProspect.css";
import { ModalAddVariablesTemplateWs } from '@/components/common/Modal/ModalAddVariablesTemplateWs';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: theme.palette.text.primary,
}));

type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   diffHrs: string | null,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

type ContentChats = {
   iddetail_chat: number,
   idchats: number,
   idusuario: number | null,
   idsend_type: number,
   send_date: string | null,
   name_user: string | null,
   id_ws_prospect: number,
   idchat_type: number,
   sendTypeName: string,
   message: string,
   sendHeader:  string | null,
   imgHeader:  string | null,
   sendFooter:  string | null,
   viewed: string,
   codeWS: string,
   textIdWs: string,
   textIdWsReference: string | null,
}

type SnackBar = {
   open: boolean,
   severity: any,
   message: string,
   autoHideDuration: number,
}

type Templates = {
   id: number,
   idTemplateWs: string,
   name: string,
   selectHeader: string,
   language: string,
   category: string,
   components: string,
   textHeader: string | null,
   variablesHeader: string | null,
   inputValuesHeader: string | null,
   imageHeader: string | null,
   textBody: string,
   variablesBody: string,
   inputValuesBody: string,
   textFooter: string | null,
   arrayButtons : string | null,
   idempresa: number,
   status: string | null,
   created_at: string | null,
   updated_at: string | null,
   statusWs: string
}

type OpenData = {
   status: boolean,
   data: Templates
}


export const ChatsWsProspectView: React.FC<any> = (props: any): JSX.Element => {

   const [chats, setChats] = useState<Chats[]>([]);
   const [chatsContents, setChatsContents] = useState<ContentChats[]>([]);
   const [selectChat, setSelectChat] = useState<Chats>({
      id: 0,
      id_ws_prospect: 0,
      diffHrs: '',
      ws_p_telefono: '',
      idchat_type: 0,
      chatTypeName: '',
      status: ''
   });
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [newMesaage, setNewMessage] = useState<string>('')
   const [snackBarConfig, setSnackBarConfig] = useState<SnackBar>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })
   const [renderChats, setRenderChats] =  useState<number>(0);
   const [selectNumber, setSelectNumber] =  useState<number>(0);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false);
   const [saveImageChat, setSaveImageChat] = useState<any>({
      name: '',
      path: '',
      data: null,
      preview: null
   });
   const [openDrawer, setOpenDrawer] = useState<boolean>(false);
   const [openData, setOpenData] = useState<OpenData>({
      status: false,
      data: {
         id: 0,
         idTemplateWs: '',
         name: '',
         selectHeader: '',
         language: '',
         category: '',
         components: '',
         textHeader: '',
         variablesHeader: '',
         inputValuesHeader: '',
         imageHeader: '',
         textBody: '',
         variablesBody: '',
         inputValuesBody: '',
         textFooter: '',
         arrayButtons : '',
         idempresa: 0,
         status: '',
         created_at: '',
         updated_at: '',
         statusWs: ''
      }
   });
   const [wsProspectIdChat, setWsProspectIdChat] = useState<any>();

   const open = Boolean(anchorEl);
   const handleClick = (event: MouseEvent<HTMLElement>) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };


   useEffect(() => {
      if (renderChats === 0) {
         getChatsVigents()
      }
      if (renderChats === 1) {
         getChatsByUsers()
      }
   }, [renderChats])

   useEffect(() => {
      if (selectChat?.id && selectChat?.id == props?.$store?.ChatsReducer?.chats?.idchats) {
         handleClickChatDetailWebhook(props?.$store?.ChatsReducer?.chats)
      }
      if (props?.$store?.ChatsReducer?.chats?.idchats) {
         AddChatProspect(props?.$store?.ChatsReducer?.chats?.idchats)
      }

   }, [props?.$store?.ChatsReducer])


   const AddChatProspect = async (idchats: number) => {
      getChatsVigents()
      // const valueChats =  chats.find(item => item.id == idchats);
      // console.log('valueChats1', valueChats)
      // if (valueChats && Object.keys(valueChats).length > 0) {
      //    console.log('valueChats3', valueChats)
      // } else {
      //    console.log('valueChats2', valueChats)
      //    const resp: any = await chatService.getChatsVigentsById(idchats);
      //    console.log('resp?.data?.detail[0]', resp?.data?.detail[0])
      //    if (resp?.code === 200) {
      //       chats.push(resp?.data?.detail[0])
      //    }
      // }
      // if (chats.length > 0) {
      //   const valueChats =  chats.find(item => item.id == idchats);
      //   console.log('valueChats1', valueChats)
      //    if (valueChats && Object.keys(valueChats).length > 0) {
      //       console.log('valueChats', valueChats)
      //    }else{
      //       // const resp: any = await chatService.getChatsVigentsById(idchats);
      //       // if (resp?.code === 200) {
      //       //    const newArray =  chats.concat(resp?.data?.detail)
      //       //    setChats(newArray);
      //       // }
      //       getChatsVigents()
      //    }
      // }
   }

   const getChatsVigents = async () => {
      const resp: any = await chatService.getChatsVigents();
      if (resp?.code === 200) {
         resp?.data?.detail && setChats(resp?.data?.detail)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const getChatsByUsers = async () => {
      const resp: any = await chatService.getChatsByUsers();
      if (resp?.code === 200) {
         resp?.data?.detail && setChats(resp?.data?.detail)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const handleClickChatDetailWebhook = async (chats) => {
      const resp: any = await chatService.getDetailChats(chats?.idchats);
      if (resp?.code === 200) {
         resp?.data?.detail && setChatsContents(resp?.data?.detail)
      } else {
         setChatsContents([])
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }


   const handleClickChat = async (chat: Chats) => {
      console.log('chat', chat);
      setSelectChat(chat);
      const resp: any = await chatService.getDetailChats(chat?.id);
      if (resp?.code === 200) {
         resp?.data?.detail && setChatsContents(resp?.data?.detail)
      } else {
         setChatsContents([])
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const handleSendMessage = async () => {
      if (newMesaage && selectChat?.id) {
         const dataUser = readLocalStorage(KEY_USER_DATA);
         const id_usuario = dataUser?.user?.idusuario;
         chatsContents.push({
            name_user: dataUser?.user?.nombre,
            idsend_type: 1 ,
            message: newMesaage,
            idusuario: id_usuario,
            send_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            sendHeader: null,
            imgHeader: null,
            sendFooter: null,
            textIdWsReference: null,
            textIdWs: '',
            viewed:'',
            sendTypeName: '',
            codeWS:'1',
            iddetail_chat: 0,
            idchats: 0,
            id_ws_prospect: 0,
            idchat_type: 0,
         })

         const resp: any = await chatService.sendMessage(selectChat?.id, { idsend_type: 1, send: newMesaage });
         if (resp?.code === 200) {
            // resp?.data?.detail && chatsContents.push({})
            setNewMessage('')
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleSendMessageImage = async () => {
      if(!selectChat?.id){
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'seleccione un chat',
         }));
      }
      if (saveImageChat?.data && selectChat?.id) {
         const dataUser = readLocalStorage(KEY_USER_DATA);
         const id_usuario = dataUser?.user?.idusuario;
         chatsContents.push({
            name_user: dataUser?.user?.nombre,
            idsend_type: 1 ,
            message: newMesaage,
            idusuario: id_usuario,
            send_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            sendHeader: null,
            imgHeader: saveImageChat?.preview,
            sendFooter: null,
            textIdWsReference: null,
            textIdWs: '',
            viewed:'',
            sendTypeName: '',
            codeWS:'1',
            iddetail_chat: 0,
            idchats: 0,
            id_ws_prospect: 0,
            idchat_type: 0,
         })
         const formData = new FormData();
         formData.append("idsend_type", '2');
         formData.append('imageChat',saveImageChat?.data || null);
         formData.append('send', 'null');
         formData.append('idusuario', id_usuario);
         const resp: any = await chatService.sendMessageImage(selectChat?.id, formData);
         if (resp?.code === 200) {
            // resp?.data?.detail && chatsContents.push(resp?.data?.detail)
            closeModalUpload();
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleParticipantChat = async () => {
      if (selectChat?.id) {
         const resp: any = await chatService.createParticipantChat({ idChat: selectChat?.id });
         if (resp?.code === 200) {
            setRenderChats(1)
            // setOpenDrawer(false)
            resp?.data?.detail && setSelectChat(resp?.data?.detail);
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));

         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleCloseChat = async () =>{
      if (selectChat?.id) {
         const resp: any = await chatService.closeChat({ idChat: selectChat?.id });
         if (resp?.code === 200) {
            setRenderChats(1)
            initialChats()
            getChatsByUsers()
            setOpenDrawer(false)
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));

         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   function processImage(event) {
      if (event && event.target.files && event.target.files.length > 0) {
        const imageFile = event.target.files[0];
        console.log(imageFile)
        const imageUrl = URL.createObjectURL(imageFile);
        setSaveImageChat(prev => ({ ...prev, data: imageFile, preview : imageUrl}))
        setShowPreviewImage(true);
      } else {
        setSaveImageChat(prev => ({ ...prev, data: null, preview : null}))
      }
    }
    const closeModalUpload = () => {
      setShowPreviewImage(false);
      setSaveImageChat(prev => ({ ...prev, data: null, preview : null}))
    }



   const handleRenderChats = (value: number) => {
      initialChats()
      setRenderChats(value)
   }

   const initialChats = () =>{
      setSelectChat({
         id: 0,
         diffHrs: '',
         id_ws_prospect: 0,
         ws_p_telefono: '',
         idchat_type: 0,
         chatTypeName: '',
         status: ''
      })
      setChats([])
      setChatsContents([])
   }



   const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        // Insertar un salto de línea en el valor del mensaje
        setNewMessage((prevMessage) => prevMessage + '\n');
        // Prevenir el comportamiento por defecto de Enter
        event.preventDefault();
      }

      if (!event.ctrlKey && event.key === 'Enter') {
         event.preventDefault(); // Prevent default behavior
         handleSendMessage(); // Execute your custom action
       }
    };

   const handleTemplate = (template:Templates) => {
      console.log('template', template)
      setOpenData({status: true, data: template})
   }

   const handleSendTemplate = (template: ContentChats) => {
      console.log('template22', template)
      handleClose();
      template && chatsContents.push(template)
   }

   return (
      <Protected>
         <div className="main__chatbody">
            <ChatList
            chats={chats}
            handleRenderChats={handleRenderChats}
            renderChats={renderChats}
            handleClickChat={handleClickChat}/>

            <ChatContent
            chatsContents={chatsContents}
            selectChat={selectChat}
            renderChats={renderChats}
            newMesaage={newMesaage}
            handleParticipantChat={handleParticipantChat}
            handleSendMessage={handleSendMessage}
            handleKeyDown={handleKeyDown}
            processImage={processImage}
            setNewMessage={setNewMessage}
            setOpenDrawer={setOpenDrawer}
            handleTemplate={handleTemplate}
            open={open}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            />

            <UserProfile
            selectChat={selectChat}
            renderChats={renderChats}
            setOpenModal={setOpenModal}
            setOpenDrawer={setOpenDrawer}
            />
         </div>

         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <ModalProspectoRapidoWS
           open={openModal}
           setOpen={setOpenModal}
           phone={selectChat}
           refreshData={getChatsByUsers}
         />
         <ModalUploadImage
          open={showPreviewImage}
          handleClose={closeModalUpload}
          uploadImage={saveImageChat?.preview}
          sendMessage={handleSendMessageImage}
         />
         <DrawerList
            open={openDrawer}
            setOpen={setOpenDrawer}
            selectChat={selectChat}
            renderChats={renderChats}
            handleParticipantChat={handleParticipantChat}
            handleCloseChat={handleCloseChat}
            setOpenModal={setOpenModal}
         />
         <ModalAddVariablesTemplateWs
            openData={openData}
            setOpenData={setOpenData}
            phone={selectChat?.ws_p_telefono}
            handleSendTemplate={handleSendTemplate}
         />
      </Protected>
   )
};
