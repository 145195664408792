import { Button, Card, CardActions, CardContent, CardHeader, Divider, Typography } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { useState } from "react";
import { DrawerDetallePagoProductos } from "./DrawerDetallePagos";

export const DetallePagos: React.FC<any> = (props): JSX.Element => {
    
    const { dataArriendo, data, getArriendo } = props;
    
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [itemSelected, setItemSelected] = useState<any>(null);

    const confirmarPago = async (idpago) => {
        const response = await ArriendosService.confirmarPago(idpago);
        if (!!response) {
          await getArriendo(data?.id);
        }
      }
    
      const rechazarPago = async (idpago) => {
        const response = await ArriendosService.rechazarPago(idpago);
        if (!!response) {
          await getArriendo(data?.id);
        }
      }

      const verDetallePago = async(item) => {
 console.log(item)
 setItemSelected(item)
 setOpenDrawer(true)
      }

      const verDocumento = (item) => {
        window.open(item?.url_doc_facturacion, '_blank');
      }
    return (
        <div style={{ minHeight: "300px", background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", overflowY: 'scroll', maxHeight: '400px' }}>
              <Typography variant="h4" sx={{ color: "#155172", fontWeight: "bold" }}>
                Detalle de Pagos
              </Typography>
              {(dataArriendo?.cobranzas || []).map((item: any) => (
                <Card key={item.id} style={{ marginBottom: "10px" }}>
                  <CardHeader title={`Pago # ${item?.cod_cobranza}` || ""} subheader={`Monto: $ ${item?.monto_total} | Fecha inicio: ${item.fecha_inicio} | Fecha fin: ${item?.fecha_fin}  ${item?.status_pay != 0 ? `| N° pago: ${item?.num_pago_adjunto}` : ''} | Estado : ${item?.status_name}`} />
                  <Divider />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                        margin: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        backgroundColor: "#155172",
                        color: "white",
                        padding: '20px',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                        }
                    }}
                    onClick={() => verDetallePago(item)}
                >
                    Ver Detalle de Pago
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                        marginBottom: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        backgroundColor: "#155172",
                        color: "white",
                        padding: '20px',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                        }
                    }}
                    onClick={() => verDocumento(item)}
                >
                    Ver Factura
                </Button>
                {/* <Typography>{item?.url_doc_facturacion}</Typography> */}
                </Card>
              ))}
              {
                openDrawer &&
                <DrawerDetallePagoProductos
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                itemSelected={itemSelected}
                getArriendo={getArriendo}
                data={data}
                />
              }
            </div>
    )
}