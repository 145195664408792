import { FunctionComponent, useMemo, useEffect, useRef, useState } from "react";
import { Button, InputAdornment, IconButton, Grid, CircularProgress, Snackbar, Alert, FormControl } from '@mui/material';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";

import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from "@assets/img/logo-softnet.png";
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import * as qs from 'qs'
import { ROUTE_ACTIVIDADES, ROUTE_COTIZACION, ROUTE_ENTITY, ROUTE_HOME, ROUTE_PROGRAMATION } from "@/toolbox/constants/route-map";
// import  logo from "@assets/svg/s-c-g-logo.svg";
import { authenticationService } from '@service/services/Authentication.service';
import { Link, useHistory } from 'react-router-dom'
import { green } from '@mui/material/colors';
import { Props } from './login.type'
import "./Login.sass";
import { ModalCotizacion } from "@/components/common/Modal/ModalCotizacion";
import { ModalSentPassword } from "@/components/common/Modal";
import { authenticationSoftnetService } from "@/service/api/services/AuthentificacionSoftnet.service";
import { RecoverymailService } from "@/service/services/Recovery.service";
import { notifyService } from "@/service/services/Notify.service";
import { bypassService } from "@/service/services/Bypass.service";
import { KEY_BYPASS, KEY_TOKEN_KYTE, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ROLE_ADMIN, ROLE_ADMIN_PROYECCION, ROLE_SUPERVISOR, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
// import firebase from '@/config/firebase';
import Pusher from "pusher-js"
import Echo from 'laravel-echo'
import { Toaster, toast } from 'react-hot-toast';

const theme = createTheme();


// declare global {
//    interface Window { Pusher: any; Echo:any }
// }
// declare const window: WWindow &
//    typeof globalThis & {
//       Pusher: any,
//       Echo:any
//    }

window['Pusher'] = require('pusher-js');

export const LoginView: React.FC<Props> = (props: any): JSX.Element => {
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loadData, setLoadData] = useState<boolean>(false);
   const [open, setOpen] = useState<boolean>(false)
   const [tokenNotify, setTokenNotify] = useState<any>();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({
      user_nick: '',
      rut_empresa: '',
      password: '',
      textError: '',
      showPassword: false
   });
   // const msg = firebase.messaging();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [verify, setVerify] = useState(false);

   const ruta = props.location.search + '.';
   const { rut_empresa, usuario, password } = props.location && qs.parse(ruta.slice(1, -1));
   useEffect(() => {
      // const msg=firebase.messaging();
      // msg.requestPermission().then(()=>{
      //   return msg.getToken();
      // }).then((data)=>{
      //   console.log("token",data)
      //   setTokenNotify(data);
      // })
      if (rut_empresa && usuario && password) {
         var decodeRutEmpresa;
         var decodeUser;
         var decodePassword;
         console.log('hola')

         try {
            decodeRutEmpresa = atob(rut_empresa);
            decodeUser = atob(usuario);
            decodePassword = atob(password);
            authSubmit(decodeUser, decodeRutEmpresa, decodePassword, 'erp');
         } catch (error) {
         }
      } else {
         //  if(session && session.token){
         //    props.history.push("/inicio");
         //  }else{
         //    props.history.push("/");
         //  }
      }



   }, []);

   const rules = useMemo(() => ({
      user_nick: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      rut_empresa: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);

      return valid;
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      switch (name) {
         case 'user_nick':
            // if ((/^[\w@.-]{0,86}$/).test(value)) {

            // }
            setVerify(false)
            setData(prev => ({ ...prev, user_nick: value, textError: '' }));
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setData(prev => ({ ...prev, password: value, textError: '' }));
            }
            break;
         case 'rut_empresa':
            setVerify(false)
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut: function (rutCompleto) {
                  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                     return false;
                  var tmp = rutCompleto.split('-');
                  var digv = tmp[1];
                  var rut = tmp[0];
                  if (digv == 'K') digv = 'k';
                  return (Fn.dv(rut) == digv);
               },
               dv: function (T) {
                  var M = 0, S = 1;
                  for (; T; T = Math.floor(T / 10))
                     S = (S + T % 10 * (9 - M++ % 6)) % 11;
                  return S ? S - 1 : 'k';
               }
            }

            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setData(prev => ({ ...prev, rut_empresa: foo, textError: '' }))
            } else if (foo.length == 0) {
               setData(prev => ({ ...prev, rut_empresa: "", textError: '' }))
            }

            break;
         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }

   // const TriggerNotify=()=>
   // {
   //    window.Echo = new Echo({
   //       broadcaster: 'pusher',
   //       key: 'crm_key',
   //       wsHost: window.location.hostname,
   //       wsPort: 6001,
   //       disableStats: true
   //     });

   //     const user:any = {} || {};
   //     window.Echo.private("user." + user.id).listen('notificationMessage', (e) => {

   //    })
   // }

   const ConsultarCotizacion = async () => {
      // const { user_nick, rut_empresa, password } = data;

      // if(validate()){
      //    const response = await authenticationService.loginCotizacion(user_nick, password,rut_empresa);

      //    if(response.token!==undefined){
      //       const usuarioEncrip = Buffer.from(user_nick).toString('base64');
      //       const rutEncrip = Buffer.from(rut_empresa).toString('base64');
      //       const passwordEncrip = Buffer.from(password).toString('base64');
      //       console.log(usuarioEncrip+" "+rutEncrip+" "+passwordEncrip)
      //       const ruta =`http://cotizacion.com?usuario=${usuarioEncrip}&&rut=${rutEncrip}&&password=${passwordEncrip}`
      //      // window.open(ruta)
      //    }
      // }
      const { user_nick, rut_empresa, password } = data;
      try {
         if (validate()) {
            const response = await authenticationService.loginCotizacion(user_nick, password, rut_empresa);
            if (response.data?.token === '') {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: "Acceso no autorizado",
               }));
               //setLoading(false);
            }
            if (response.message === 'Usuario no autorizado') {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: "Acceso no autorizado",
               }));
               //setLoading(false);
            }
            else {
               history.push(ROUTE_COTIZACION);
               // setLoading(false)
            }
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
      }
   }

   const RecuperarCuenta = async (e) => {
      e.preventDefault();
      if (data.user_nick && data.rut_empresa) {

         const resp: any = await RecoverymailService.sendMailRecovery(data.user_nick, data.rut_empresa)

         if (resp.data?.message.descripcion == 'Mensaje enviado') {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'info',
               message: 'Se le envió un correo',
            }));
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: 'Las credenciales ingresadas no son válidas',
            }));
         }
      } else {
         setVerify(true)
      }

   }

   const loginBypass = async () => {
      const bypass = await bypassService.loginByPass('dsf', 'sdf', 'sfd');
      saveLocalStorage(KEY_BYPASS, bypass.token.original.access_token)
   }


   const handleSubmit = (event: React.SyntheticEvent) => {
      event.preventDefault();

      const { user_nick, rut_empresa, password } = data;
      try {
         if (validate()) {
            authSubmit(user_nick, rut_empresa, password, '');

            loginBypass();
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {

      }
   }

   const authSubmit = async (user_nick, rut_empresa, password, from) => {
      setLoading(true);
      const response = await authenticationService.login(user_nick, rut_empresa, password, from, tokenNotify);
      if (response.data?.token == '') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: response.data.message,
         }));
      } else {
         const data = readLocalStorage(KEY_USER_DATA)
         console.log('ddddd', readLocalStorage(KEY_TOKEN_KYTE))
         const resNotify = await notifyService.getNotiNoVistos(5, 1)
         console.log(resNotify)
         props.$action.actionSetListNotification(resNotify.data.cantidad)
         if (data) {
            window['Echo'] = new Echo({
               broadcaster: 'pusher',
               key: 'crmkey',
               wsHost: window.location.hostname,
               wsPort: 6004,
               cluster: 'mt1',
               // wssPort: 6001,
               disableStats: true,
               forceTLS: false,
               encrypted: false,
               enabledTransports: ['ws', 'wss'],
               authEndpoint: `${process.env.REACT_APP_API_URL}/api/broadcasting/auth`,
               auth: {
                  headers: {
                     'Access-Control-Allow-Origin': '*',
                     Accept: 'application/json',
                     Authorization: `${readLocalStorage(KEY_TOKEN_KYTE)}`
                  }
               },
            });
            window['Echo'].private(`contador${data.user.idusuario}`).listen('CountNotification', (e) => {
               toast.success(e.count, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`vendedor${data.user.idusuario}`).listen('CampanaVendedores', (e) => {
               toast.success(e.campana, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`contador${data.user.idusuario}`).listen('CountNotification', (e) => {
               toast.success(e.count, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`clienteVendedor${data.user.idusuario}`).listen('ClienteVendedor', (e) => {

               toast.success(e.message, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`completarTarea${data.user.idusuario}`).listen('CompletarTarea', (e) => {

               toast.success(e.name + ' Completo la tarea: ' + e.contenido + ' del cliente: ' + e.cliente)
            })
            window['Echo'].private(`Proyeccion${data.user.idusuario}`).listen('ProyeccionUsuarios', (e) => {

               toast.success(e.nameProyeccion, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`accion${data.user.idusuario}`).listen('AccionTrabajadores', (e) => {

               toast.success(e.asunto, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`accionCompletada${data.user.idusuario}`).listen('AccionCompletada', (e) => {
               toast.success('El usuario: ' + e.userCompletado + ' completó la acción: ' + e.nameAccion)
            })
            window['Echo'].private(`notificacion${data.user.idusuario}`).listen('Notificacion', (e) => {

               props.$action.actionSetListNotification(e.contador, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
               //  toast.success('Tienes ' + e.contador + ' notificaciones sin visualizar')
            })
            window['Echo'].private(`tareaCreada${data.user.idusuario}`).listen('CrearTarea', (e) => {
               console.log(e)
               toast.success(e.nameTarea, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`newCampana${data.user.idusuario}`).listen('NewCampana', (e) => {
               console.log(e)
               toast.success(e.cantidad, {
                  duration: 5000, icon: '🛎️',
                  style: {
                     borderRadius: '10px',
                     background: '#333',
                     color: '#fff',
                  },
                  iconTheme: {
                     primary: '#713200',
                     secondary: '#FFFAEE',
                  },
               })
            })
            window['Echo'].private(`proyeccionCompletada${data.user.idusuario}`).listen('ProyeccionRealizada', (e) => {
               console.log(e)
               toast.success(e.nameProyeccion)
            })
            window['Echo'].private(`wsprospect${data.user.idusuario}`).listen('WsProspectEvent', (e) => {
               console.log(e)
               // toast.success(e.send)
            })
         }

         if (data.user.role == ROLE_SUPER_ADMIN) {
            history.push(ROUTE_PROGRAMATION);
         }
         if (data.user.role == ROLE_ADMIN) {
            history.push(ROUTE_HOME)
         }
         if (data.user.role == ROLE_SUPERVISOR) {
            history.push(ROUTE_HOME
            )
         }
         if (data.user.role == ROLE_VENDEDOR) {
            history.push(ROUTE_HOME
            )
         }
         if (data.user.role == ROLE_TRABAJADOR) {
            history.push(ROUTE_PROGRAMATION
            )
         }
         if (data.user.role == ROLE_ADMIN_PROYECCION) {
            history.push(ROUTE_PROGRAMATION
            )
         }


      }

      setLoading(false);
   }
   const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <>
         <ThemeProvider theme={theme}>
            <Toaster
               position="bottom-right"
               reverseOrder={false}

            />
            <Grid container component="main" sx={{ height: '100vh' }}>
               <CssBaseline />
               <Grid
                  item
                  xs={false}
                  sm={6}
                  md={7}
                  sx={{
                     backgroundImage: `url(${logoLogin})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                  }}
               />
               <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square >
                  <Box
                     sx={{
                        my: 5,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                     }}>

                     {/* <Box
                        component="img"
                        sx={{
                           mt: 3,
                           mb: 3,
                           height: " 55px"
                        }}
                        src={logo}>
                     </Box> */}
                     <Grid sx={{ border: '1px solid #155172', borderTop: '8px solid #155172', borderRadius: '7px', width: '80%' }}>
                        <form className="LoginForm" onSubmit={handleSubmit}>
                           {/* <header className="Header">
                              <h2>Iniciar sesión</h2>
                           </header> */}
                           <Grid container spacing={2} className={`e-mb-lg`}>
                              <Grid item xs={12}>
                                 <Typography textAlign={'center'} sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em' }} >Sesión de Usuario</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[0] = ref}
                                    label="Nombre de Usuario"
                                    name="user_nick"
                                    placeholder="Ingrese su nombre de usuario"
                                    value={data.user_nick}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    rules={rules.user_nick}
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[1] = ref}
                                    label="RUT Empresa"
                                    name="rut_empresa"
                                    type="text"
                                    placeholder="Ingrese su RUT Empresa"
                                    value={data.rut_empresa}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    rules={rules.rut_empresa}
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Input
                                    ref={ref => inputRefs.current[2] = ref}
                                    label="Contraseña"
                                    name="password"
                                    type={data.showPassword ? 'text' : 'password'}
                                    placeholder="Ingrese su contraseña"
                                    value={data.password}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    //rules={rules.password}
                                    appendAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                             onMouseDown={handleMouseDownPassword}
                                          >
                                             {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                              <Grid item xs={12}>

                                 {verify ? (<span style={{ color: 'red' }}>(*) Ingresa el Usuario y Rut</span>) : (<></>)}
                                 <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                       fullWidth={true}
                                       type='submit'
                                       variant='contained'
                                       onClick={handleSubmit}
                                       disabled={loading}
                                       sx={{
                                          background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#E4980D'
                                          }
                                       }}
                                       disableElevation
                                    >
                                       <span>Iniciar sesión</span>
                                    </Button>
                                    {loading && (
                                       <CircularProgress
                                          size={24}
                                          sx={{
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: '-12px',
                                             marginLeft: '-12px',
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Grid>
                           </Grid>
                           <span className="TextError">{data.textError}</span>

                           <Grid container className={`e-mt-sm`} sx={{ mt: 1 }}>
                              <Grid item xs={12} m={1} container justifyContent="center">
                                 <Button
                                    fullWidth={true}
                                    color='secondary'
                                    variant='contained'
                                    sx={{
                                       background: '#0C879C', color: '#fff', fontWeight: '700', '&:hover': {
                                          bgcolor: '#0C879C'
                                       }
                                    }}
                                    onClick={() => { ConsultarCotizacion() }}
                                    disableElevation
                                 >
                                    Consultar Cotización
                                 </Button>
                              </Grid>

                              <Grid item xs={12} container justifyContent="center">
                                 <span>¿Olvidaste tu contraseña?</span>
                              </Grid>
                              <Grid item xs={12} container justifyContent="center">
                                 <Button onClick={RecuperarCuenta}>Recuperar Cuenta</Button>
                              </Grid>
                           </Grid>
                        </form>
                     </Grid>
                     <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
                  </Box>
               </Grid>
            </Grid>
            <ModalSentPassword
               open={open}
               setOpen={setOpen}
            />
         </ThemeProvider>

         {loadData && <Loading title="Iniciando Sesión" />}
      </>
   )
}
