import React, { useState } from "react";
import "./userProfile.css";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Grid } from "@mui/material";
type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   diffHrs: string | null,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

interface ChatProfileProps {
   selectChat: Chats;
   renderChats: number;
   setOpenModal: (value: boolean) => void;
   setOpenDrawer: (value: boolean) => void;
 }

const UserProfile: React.FC<ChatProfileProps> = ({
   selectChat,
   renderChats,
   setOpenModal,
   setOpenDrawer
 }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  return (
    <div className="main__userprofile">
      <div className="profile__card user__profile__image">
        <div className="profile__image">
          <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="User Profile" />
        </div>
      <div >
      {selectChat?.nameRzClient && <p className="profile__card_text"> Nombre prospecto: {selectChat?.nameRzClient} </p>}
        {selectChat?.rutClient && <p className="profile__card_des">Rut prospecto: {selectChat?.rutClient}</p>}
        {selectChat?.nameContact && <p className="profile__card_des">Nombre contacto: {selectChat?.nameContact}</p>}
        {selectChat?.ws_p_telefono && <p className="profile__card_des"> Celular contacto: {selectChat?.ws_p_telefono}</p>}
         <p className="profile__card_des">{`Horas vigente: ${selectChat?.diffHrs}/24 horas`}</p>
              {renderChats === 1 && selectChat.id > 0 && selectChat?.idClient === null && <Grid item xs={12} >
                 <Button variant='outlined' onClick={() => { setOpenModal(true) }}>Guardar Contacto</Button>
              </Grid>}
      </div>
      </div>
     {renderChats === 1 && selectChat.id > 0 ? <div className={`profile__card ${isInfoOpen ? "open" : ""}`}>
        <div className="card__header" onClick={() => { setOpenDrawer(true) }}>
          <h4>Otras Acciones</h4>
          <ChevronRightIcon/>
          {/* <i className={`fa fa-angle-${isInfoOpen ? "up" : "down"}`}></i> */}
        </div>

      </div>:
      <div className={`profile__card ${isInfoOpen ? "open" : ""}`}>
      <div className="card__header" >
        <h6 style={{color:'red'}}>únete al chat para que puedas acceder a las acciones</h6>
      </div>

    </div>
      }
    </div>
  );
};

export default UserProfile;
