import React, { useState, useRef, useEffect } from "react";
import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { Button, Grid, Icon, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
import CustomizedMenus from "../menuOptions/menuOptions";

type Templates = {
   id: number,
   idTemplateWs: string,
   name: string,
   selectHeader: string,
   language: string,
   category: string,
   components: string,
   textHeader: string | null,
   variablesHeader: string | null,
   inputValuesHeader: string | null,
   imageHeader: string | null,
   textBody: string,
   variablesBody: string,
   inputValuesBody: string,
   textFooter: string | null,
   arrayButtons : string | null,
   idempresa: number,
   status: string | null,
   created_at: string | null,
   updated_at: string | null,
   statusWs: string
}

interface ChatContentProps {
  chatsContents : ContentChats[];
  selectChat: Chats;
  renderChats: number;
  newMesaage: string;
  handleParticipantChat: () => Promise<void>;
  handleSendMessage: () => Promise<void>;
  setNewMessage: (value: string) => void;
  processImage: (value: any) => void;
  setOpenDrawer: (value: boolean) => void;
  handleTemplate: (template: Templates) => void;
  handleKeyDown: any;
   open: boolean;
   anchorEl: any;
   handleClick: (event:any) => void;
   handleClose: () => void;
}
type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   diffHrs: string | null,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}
type ContentChats = {
   iddetail_chat: number,
   idchats: number,
   idusuario: number | null,
   idsend_type: number,
   send_date: string | null,
   name_user: string | null,
   id_ws_prospect: number,
   idchat_type: number,
   sendTypeName: string,
   message: string,
   sendHeader:  string | null,
   imgHeader:  string | null,
   sendFooter:  string | null,
   viewed: string,
   codeWS: string,
   textIdWs: string,
   textIdWsReference: string | null,
}


const ChatContent: React.FC<ChatContentProps> = ({
   chatsContents,
   selectChat,
   renderChats,
   newMesaage,
   handleParticipantChat,
   handleSendMessage,
   handleKeyDown,
   setNewMessage,
   processImage,
   handleTemplate,
   open,
   anchorEl,
   handleClick,
   handleClose,
 }) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [msg, setMsg] = useState<string>("");
  const dataUser = readLocalStorage(KEY_USER_DATA);

  const handleSearchIdWsReference = (idReference) => {
   if(idReference && chatsContents.length>0){
      return chatsContents.find(item => item?.textIdWs == idReference)
   }
   return null
}

  return (
    <div className="main__chatcontent">
      <div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">
            <Avatar
              isOnline={true}
              image="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU"
            />
            <p> {selectChat?.nameRzClient} {selectChat?.ws_p_telefono? (selectChat?.ws_p_telefono) : '' }</p>

          </div>
        </div>

        <div className="blocks">
            <div className="settings">
               {renderChats === 0 && selectChat.id > 0 && <Grid item >
                  <Button variant="outlined" onClick={() => { handleParticipantChat() }}>Unirme al chat</Button>
               </Grid>}
               {/* {renderChats === 1 && selectChat.id > 0 && <Grid item  container direction={'row'} justifyContent={'end'}>
                  <IconButton onClick={() => { setOpenDrawer(true) }}>
                     <MenuIcon />
                  </IconButton>
               </Grid>} */}
            </div>
        </div>
      </div>
      <div className="content__body">
        <div className="chat__items">
        {chatsContents.length > 0 && chatsContents.map((chatsContent, index) =>(
            <ChatItem
            key={index}
            user={chatsContent?.name_user}
            msg={chatsContent?.idsend_type == 1 ? chatsContent?.message : ''}
            isMyMessage={chatsContent?.idusuario == dataUser?.user?.idusuario}
            animationDelay={index + 2}
            hour={chatsContent?.send_date}
            codeWS = {chatsContent?.codeWS}
            sendHeader = {chatsContent?.sendHeader}
            imgHeader = {chatsContent?.imgHeader}
            sendFooter = {chatsContent?.sendFooter}
            referenceObj = {handleSearchIdWsReference(chatsContent?.textIdWsReference)}
         />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="content__footer">
          <Grid item xs={12}>
                  <Paper >
                     <Grid container xs={12} display="flex" justifyContent="space-between" alignItems="center">
                     <InputBase
                           sx={{ ml: 1, flex: 1 }}
                           multiline
                           rows={2}
                           disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                           value={newMesaage}
                           onChange={(e) => setNewMessage(e?.target?.value || "")}
                           placeholder="Escribe un Mensaje"
                           name='mensaje'
                           id='mensaje'
                           onKeyPress={event => { event.key === 'Enter' && handleSendMessage() }}
                           onKeyDown={handleKeyDown}
                        />
                        {/* <Grid item xs={1} >

                        <input accept="image/*" style={{ display: 'none' }} id="upload-image" type="file" onChange={processImage}  disabled={renderChats === 0 || selectChat.id === 0 ? true : false} />
                           <label htmlFor="upload-image" >
                           <Tooltip title='Agregar Imagen'>
                              <IconButton component="span" disabled={renderChats === 0 || selectChat.id === 0 ? true : false}>
                                 <ImageIcon className="chat-footer-icon" />
                              </IconButton>
                         </Tooltip>
                           </label>
                        </Grid> */}
                        <Grid item xs={1} >
                          <Tooltip title='Enviar mensaje'>
                          <IconButton
                              type="button"
                              sx={{ p: "10px" }}
                              aria-label="search"
                              disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                              onClick={(e) => { handleSendMessage() }}
                           >
                              <SendIcon />
                           </IconButton>
                          </Tooltip>
                        </Grid>

                     </Grid>
                  </Paper>
               </Grid>
               <Grid item xs={12} sx={{mt:2}} >
                     <Grid container xs={12} display="flex" alignItems="center">
                 <Grid >
                    <input accept="image/*" style={{ display: 'none' }} id="upload-image" type="file" onChange={processImage} disabled={renderChats === 0 || selectChat.id === 0 ? true : false} />
                    <label htmlFor="upload-image" >
                       <Tooltip title='Insertar Imagen'>
                       <Button
                       component="span"
                       disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                    endIcon={<>⌵</>}
                 >
                    Insertar Imagen
                 </Button>
                          {/* <IconButton component="span" disabled={renderChats === 0 || selectChat.id === 0 ? true : false}>
                             <ImageIcon className="chat-footer-icon" />
                          </IconButton> */}
                       </Tooltip>
                    </label>
                 </Grid>

                     <CustomizedMenus
                        handleTemplate={handleTemplate}
                        open={open}
                        anchorEl={anchorEl}
                        disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                        handleClick={handleClick}
                        handleClose={handleClose}
                          />
                     </Grid>
               </Grid>
      </div>
    </div>
  );
};

export default ChatContent;
