import { ArriendosService } from "@/service/services/Arriendos.service.";
import {
    Box,
    Button,
    Divider,
    Drawer,
    FormControl,
    FormLabel,
    TextField,
    Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
export const DrawerGarantia: React.FC<any> = (props): JSX.Element => {
    const { openDrawer, setOpenDrawer, getArriendo, data } = props;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
console.log(data)
    const confirmarGarantia = async (idgarantia) => {
        const response = await ArriendosService.confirmarGarantia(idgarantia);
        if (!!response) {
            setOpenDrawer(false)
        }
    }

    const rechazarGarantia = async (idgarantia) => {
        const response = await ArriendosService.rechazarGarantia(idgarantia);
        if (!!response) {
            setOpenDrawer(false)
        }
    }

    const [paymentImage, setPaymentImage] = useState(null);
    const [codePay, setCodePay] = useState(null)

    const confirmarPagoGarantias = async() => {
        
        const formData = new FormData();
        formData.append('idarriendo', data.id );
        formData.append('img_pago',paymentImage );
        formData.append('cod_pago',codePay );

        const registerArriendo = await ArriendosService.pagarGarantia(formData);
        if (!!registerArriendo?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'success' })
            await getArriendo(data?.id);
            setOpenDrawer(false)
        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'error' })
        }

    }

    const totalGarantia = data?.arriendoProductos.reduce((total, devolucion) => {
        const item = data?.arriendoProductos.find((item) => item.id === devolucion.id);
        return total + item?.cantidad_actual * item?.garantia.monto_garantia_producto;
    }, 0);

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    Información de la Garantia
                </Typography>

                <div style={{
                    borderBottom: '3px solid #155172', // Línea inferior gruesa
                    borderTop: '3px solid #155172', // Línea superior gruesa solo para el primer elemento
                    padding: '10px',
                    marginBottom: '10px',
                    height: '400px',
                    minWidth: '400px',
                    overflowY: 'auto',
                    margin: '0 auto', // Centrar el contenedor en el drawer
                }}>

                    {
                        data?.arriendoProductos.map((item, index) => (
                            <div key={item.id} style={{
                                borderBottom: index <  data?.arriendoProductos.length - 1 ? '1px solid #ddd' : 'none', // Línea delgada entre elementos
                                paddingBottom: '10px',
                                paddingTop: '5px',
                                marginBottom: '10px',
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }}>

                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                    {`(${item?.producto?.codigo}) ${item?.producto?.nombre}`}
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {`${item?.cantidad_actual} producto(s)`}
                                </Typography>

                                <Typography sx={{ color: '#000' }}>
                                    {` $ ${item?.garantia.monto_garantia_producto} CLP`}
                                </Typography>
                            </div>
                                
                        ))
                    }

                </div>

                <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#000', marginTop: "20px", marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${totalGarantia} CLP`}
                </Typography>

                {
                    data?.status_garantia == '0' &&
                    <><Typography sx={{ color: '#333', marginBottom: "10px" }}>
                    De manera opcional puede adjuntar un número de pago e imagen.
                </Typography>
                <input type="file" accept="image/*" 
                    onChange={(e) => { setPaymentImage(e.target.files[0])}}
                />

                <FormControl sx={{ marginTop: "10px" }}>
                    <TextField
                        id="cod_pago"
                        type="text"
                        multiline
                        name="cod_pago"
                        onChange={(e)=>setCodePay(e.target.value)}
                        fullWidth
                        size="small"
                        placeholder="Ingrese el número de pago como referencia"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        marginTop: "20px",
                        backgroundColor: "#155172",
                        color: "white",
                        padding: '20px',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                        }
                    }}
                    onClick={() => confirmarPagoGarantias()}
                >
                    Confirmar Pago de Garantia
                </Button>

                    </>
                }
                
            </Box>
        </Drawer>

    );
};
