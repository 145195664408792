import React from "react";
import Avatar from "../chatList/Avatar";
import moment from 'moment';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { Divider } from "@mui/material";

interface ChatItemProps {
   user: string;
   msg: string;
   image?: string;
   animationDelay: number;
   isMyMessage: boolean;
   imageUpload?: string;
   file?: string;
   hour?: string;
   codeWS: string;
   sendHeader: string;
   sendFooter: string;
   imgHeader: string;
   referenceObj: any;
}

const ChatItem: React.FC<ChatItemProps> = ({
   user,
   msg,
   image,
   animationDelay,
   isMyMessage,
   imageUpload,
   file,
   hour,
   codeWS,
   sendHeader,
   sendFooter,
   imgHeader,
   referenceObj
}) => {

   const hourChat = moment(hour).format('LT') || "00:00";
   const lines = msg ? msg.split('\n') : [];
   const linesReferences = referenceObj?.message ? referenceObj?.message.split('\n') : [];
   return (
      <div
         style={{ animationDelay:  `0.8s` }}
         className={`chat-item ${isMyMessage ? "me" : "other"}`}
      >
         <div className="chat-item-content">
            <div className="chat-meta">
            <span>{isMyMessage ? "Yo" : user}</span>
               <span>{hourChat}</span>
               {/* <span>16 mins ago</span>
          <span>Seen 1.03PM</span> */}
            </div>
            {referenceObj === null ?
               <div className="chat-msg" style={{ color: codeWS != '1' ? 'red' : '#000' }}>
                  {imgHeader && (
                     <>
                        <a href={`${imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: imgHeader ? "flex" : "none" }} src={`${imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {sendHeader && (
                     <><p>{sendHeader}</p> </>
                  )
                  }
                  {msg && (
                     lines.map((line) => (
                        <span key={line}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {sendFooter && (
                     <> <br /><p>{sendFooter}</p>  <br /></>
                  )
                  }
               </div>
               :
               <div className="chat-msg" style={{ color: codeWS != '1' ? 'red' : '#000' }}>
                  {referenceObj?.imgHeader && (
                     <>
                        <a href={`${referenceObj?.imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: referenceObj?.imgHeader ? "flex" : "none" }} src={`${referenceObj?.imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {referenceObj?.sendHeader && (
                     <><p style={{ color: '#f1f1f1' }}>{referenceObj?.sendHeader}</p> </>
                  )
                  }
                  {referenceObj?.message && (
                     linesReferences.map((line) => (
                        <span key={line} style={{ color: '#f1f1f1' }}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {referenceObj?.sendFooter && (
                     <> <br /><p style={{ color: '#f1f1f1' }}>{referenceObj?.sendFooter}</p>  <br /></>
                  )
                  }
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}><Divider />
                     <Divider /></div>
                  {imgHeader && (
                     <>
                        <br />
                        <a href={`${imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: imgHeader ? "flex" : "none" }} src={`${imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {sendHeader && (
                     <><p>{sendHeader}</p> </>
                  )
                  }
                  {msg && (
                     lines.map((line) => (
                        <span key={line}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {sendFooter && (
                     <> <br /><p>{sendFooter}</p>  <br /></>
                  )
                  }
               </div>

            }
         </div>
         <Avatar isOnline={true} image={image} />
      </div>
   );
};

export default ChatItem;
