import { ArriendoRepository } from "../repositories/Arriendos.repository";

const listarArriendos = async (selectedStatus, valueFilterDates, selectedProducts) => {
  try {
    const response = await ArriendoRepository.listarArriendos(selectedStatus, valueFilterDates, selectedProducts)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}


const getArriendoById = async (id) => {
  try {
    const response = await ArriendoRepository.getArriendoById(id)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}


const getConfiguracion = async (rut_empresa) => {
  try {
    const response = await ArriendoRepository.getConfiguracion(rut_empresa)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarArriendosByCodePublic = async (codigo) => {
  try {
    const response = await ArriendoRepository.listarArriendosByCodePublic(codigo)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarArriendosByCodePublicMonthly = async (codigo) => {
  try {
    const response = await ArriendoRepository.listarArriendosByCodePublicMonthly(codigo)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarGarantiaByArriendo = async (idarriendo) => {
  try {
    const response = await ArriendoRepository.listarGarantiaByArriendo(idarriendo)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarPagosByArriendo = async (idarriendo) => {
  try {
    const response = await ArriendoRepository.listarPagosByArriendo(idarriendo)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarProductsArriendo = async (search = '', idempresa) => {
  try {
    const response = await ArriendoRepository.listarProductsArriendo(search, idempresa)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const listarStockForProducts = async (idProducts) => {
  try {
    const response = await ArriendoRepository.listarStockForProducts(idProducts)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const getDashboardFacturacionDevengados = async () => {
  try {
    const response = await ArriendoRepository.getDashboardFacturacionDevengados()
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const getDashboardEstadoGeneral = async () => {
  try {
    const response = await ArriendoRepository.getDashboardEstadoGeneral()
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const getDashboardEstadoVencimiento = async () => {
  try {
    const response = await ArriendoRepository.getDashboardEstadoVencimiento()
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const getDashboardEstadoProductos = async () => {
  try {
    const response = await ArriendoRepository.getDashboardEstadoProductos()
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}
const getDashboardDatosGenerales = async () => {
  try {
    const response = await ArriendoRepository.getDashboardDatosGenerales()
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const templateOnBoarding = async (rutEmpresa) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.templateOnBoarding(rutEmpresa)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const processtemplateOnBoarding = async (payload) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.processtemplateOnBoarding(payload)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}


const confirmOnBoarding = async (payload) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.confirmOnBoarding(payload)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const getCodesProductsArriendo = async (idproduct) => {
  try {
    const response = await ArriendoRepository.getCodesProductsArriendo(idproduct)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const getRelationProductsService = async (idservice) => {
  try {
    const response = await ArriendoRepository.getRelationProductsService(idservice)
    if (!response?.status) {
      return { status: false, message: response.data.message || "" }
    }
    const { detail } = response.data
    return { status: true, data: detail }
  } catch (error) {
    console.log(error)
    return
  }
}

const registerArriendo = async (dataArriendo: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.registerArriendo(dataArriendo)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const pagarGarantia = async (dataGarantia: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.pagarGarantia(dataGarantia)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const pagarMensualidad = async (payload: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.pagarMensualidad(payload)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const confirmarGarantia = async (idgarantia: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.confirmarGarantia(idgarantia)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}


const rechazarGarantia = async (idgarantia: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.rechazarGarantia(idgarantia)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const confirmarPago = async (idpago: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.confirmarPago(idpago)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}


const confirmarDevolucion = async ( payload) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.confirmarDevolucion(payload)
    console.log(response)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const postCodesProducts = async (payload) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.postCodesProducts(payload)
    console.log(response)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const postRelationProductsService = async (payload) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.postRelationProductsService(payload)
    console.log(response)
    if (!response?.status) {
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const rechazarPago = async (idpago: any) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.rechazarPago(idpago)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

const actualizarConfiguracion = async (rut_empresa: any, params) => {
  try {
    // dispatch(changeStatus(ApiStatus.FETCHING))
    const response = await ArriendoRepository.actualizarConfiguracion(rut_empresa, params)
    console.log(response)
    if (!response?.status) {
      console.log('hola')
      //   dispatch(changeStatus(ApiStatus.FETCHED))
      //   CustomSnackbar('error', response.data.message || "")
      return { status: false, message: response.data.message || "" }
    }
    // dispatch(changeStatus(ApiStatus.FETCHED))
    // CustomSnackbar('success', response.data.message  || "");
    return { status: true, message: response.data.message || "", data: response.data.detail }
  } catch (error) {
    console.log(error)
    return
    //   return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}


export const ArriendosService = {
  listarArriendos,
  getArriendoById,
  getConfiguracion,
  listarPagosByArriendo,
  listarProductsArriendo,
  listarStockForProducts,
  registerArriendo,
  listarArriendosByCodePublic,
  listarArriendosByCodePublicMonthly,
  getDashboardFacturacionDevengados,
  getDashboardEstadoGeneral,
  getDashboardEstadoVencimiento,
  getDashboardEstadoProductos,
  getDashboardDatosGenerales,
  templateOnBoarding,
  processtemplateOnBoarding,
  confirmOnBoarding,
  getRelationProductsService,
  getCodesProductsArriendo,
  pagarGarantia,
  pagarMensualidad,
  confirmarDevolucion,
  postCodesProducts,
  postRelationProductsService,
  listarGarantiaByArriendo,
  confirmarGarantia,
  rechazarGarantia,
  confirmarPago,
  rechazarPago,
  actualizarConfiguracion
};


