import React from "react";
interface AvatarProps {
  image: string;
  isOnline: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ image, isOnline }) => {
  return (
    <div className="avatar">
      <div className="avatar-img">
        <img src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} alt="#" />
      </div>
      <span className={`isOnline ${isOnline ? "active" : ""}`}></span>
    </div>
  );
};

export default Avatar;
