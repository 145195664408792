import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const ArriendoRepository = {
    listarArriendos: async (selectedStatus, valueFilterDates, selectedProducts): Promise<any> => {
        let ruta = `${API_URL_BASE}/v1/arriendos`;
    
        if (selectedStatus.length > 0) {
            const idsStatus = selectedStatus.map(status => status.id);
            const idsStatusJSON = JSON.stringify(idsStatus);
            ruta = `${ruta}?status=${idsStatusJSON}`;
        }
    
        if (!!valueFilterDates.start && !!valueFilterDates.end) {
            // Verifica si ya hay una cadena de consulta en la ruta
            const separator = ruta.includes('?') ? '&' : '?';
    
            ruta = `${ruta}${separator}date_start=${valueFilterDates.start}&date_end=${valueFilterDates.end}`;
        }
    
        if (selectedProducts.length > 0) {
            
            const separator = ruta.includes('?') ? '&' : '?';
            const idsProducts = selectedProducts.map(product => product.id);
            const idsProductsJSON = JSON.stringify(idsProducts);

            ruta = `${ruta}${separator}idproducts=${idsProductsJSON}`

        }
        const response = await http.get<any>(ruta)
            .then(response => response)
            .catch((error) => {
                return error.response;
            });
    
        return response;
    },
    
    getArriendoById: async (id): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/byId/${id}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    getConfiguracion: async (rut_empresa): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/configuracion/${rut_empresa}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    listarArriendosByCodePublic : async (codigo): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/public/${codigo}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    listarArriendosByCodePublicMonthly : async (codigo): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/publicMensualidad/${codigo}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    listarGarantiaByArriendo : async (idarriendo): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/garantia/${idarriendo}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    listarPagosByArriendo : async (idarriendo): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/listaPagos/${idarriendo}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    listarProductsArriendo : async (search = '', idempresa): Promise<any> => {
        let ruta = `${API_URL_BASE}/v1/arriendos/listaProductosArriendos?idempresa=${idempresa}`;
        if(!!search){
            ruta = ruta+`&search=${search}`
        }
        const response = await http.get<any>( ruta )
        .then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    listarStockForProducts : async (idProducts): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/stockProductos?idproducts=${idProducts}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    // dashboard
    getDashboardFacturacionDevengados : async (): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/dashboard/facturacion-devengados`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    getDashboardEstadoGeneral : async (): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/dashboard/estado-general`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    getDashboardEstadoVencimiento : async (): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/dashboard/estado-vencimiento`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    getDashboardEstadoProductos : async (): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/dashboard/estado-productos`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    getDashboardDatosGenerales : async (): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/dashboard/datos-generales`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },

    templateOnBoarding: async (rutEmpresa): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/onBoarding/template-arriendos`, {rut_empresa: rutEmpresa}
        ).then( response => response )
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },

    processtemplateOnBoarding: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/onBoarding/process-template`, payload
        ).then( response => response )
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },

    confirmOnBoarding: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/onBoarding/register-arriendos`, payload
        ).then( response => response )
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },

    getCodesProductsArriendo : async (idproduct): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/getCodes/${idproduct}`
        ).then( response => response )
        .catch( (error) => {
            return error.response
        })
        
        return response
    },
    getRelationProductsService: async (idservice): Promise<any> => {
        const response = await http.get<any>(
            `${API_URL_BASE}/v1/arriendos/relationServiceProduct/${idservice}`
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    //
    registerArriendo: async (dataArriendo): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos`, dataArriendo
        ).then( response => response )
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    pagarGarantia: async (dataGarantia): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/pagoGarantia`, dataGarantia
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    pagarMensualidad: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/pagoMensualidad`, payload
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    confirmarDevolucion: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/confirmarDevolucion`, payload
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    postCodesProducts: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/postCodesProducts`, payload
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    postRelationProductsService: async (payload): Promise<any> => {
        const response = await http.post<any>(
            `${API_URL_BASE}/v1/arriendos/relationServiceProduct`, payload
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    confirmarGarantia: async (idgarantia): Promise<any> => {
        const response = await http.patch<any>(
            `${API_URL_BASE}/v1/arriendos/confirmarGarantia/${idgarantia}`, 
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    rechazarGarantia: async (idgarantia): Promise<any> => {
        const response = await http.patch<any>(
            `${API_URL_BASE}/v1/arriendos/rechazarGarantia/${idgarantia}`, 
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    confirmarPago: async (idpago): Promise<any> => {
        const response = await http.patch<any>(
            `${API_URL_BASE}/v1/arriendos/confirmarPago/${idpago}`, 
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    rechazarPago: async (idpago): Promise<any> => {
        const response = await http.patch<any>(
            `${API_URL_BASE}/v1/arriendos/rechazarPago/${idpago}`, 
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },
    actualizarConfiguracion: async (rut_empresa, params): Promise<any> => {
        const response = await http.patch<any>(
            `${API_URL_BASE}/v1/arriendos/actualizarConfiguracion/${rut_empresa}?idtipo_atencion=${params?.idtipo_atencion}&idtipo_documento_facturacion=${params?.idtipo_documento_facturacion}`, 
        ).then( response => response)
        .catch( (error) => {
            return error.response.data
        })
        
        return response
    },

}