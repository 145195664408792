import React, { ChangeEvent, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Container, InputLabel, Snackbar, Alert, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment } from '@mui/material';
import { Box, Button } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { useHistory } from 'react-router-dom';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

type ModalProps = {
   open: boolean,
   setOpen: any,
   phone?: Chats,
   refreshData?: () => void,
}

const initialFormValues = {
   rut: '',
   nombre: '',
   celular: '',
   correo: '',
   nombre_rz: '',
   nombre_f: ''
}

export const ModalProspectoRapidoWS: React.FC<ModalProps> = (
   props: ModalProps
): JSX.Element => {
   const { phone, open, setOpen, refreshData } = props;
   const history = useHistory();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [error, setError] = useState(null);
   const [formValues, setFormValues] = useState(initialFormValues);
   const { rut = "", nombre = "", celular = "", correo = "", nombre_rz="", nombre_f="" } = formValues
   const [value, setValue] = useState<number>(1)

   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      // changedFormValues.rut.trim() !== "" && setError("");
      // changedFormValues.nombre.trim() !== "" && setError("");
      // changedFormValues.telefono.trim() !== "" && setError("");
      // changedFormValues.correo.trim() !== "" && setError("");

      setFormValues(changedFormValues)

   }

   const handleSubmitForm = async (e) => {
      e.preventDefault()
      let data = null;
      if (value === 1) {
         data = {
            ...formValues,
            giro: 'persona'
         }
      }
      if (value === 2) {
         data = {
            ...formValues,
            giro: 'empresa'
         }
      }
      if (!phone?.idClient) {
         console.log('data', data)
         const resp = await contactService.createProspectContact(data)
         if (resp?.code === 200) {
            setOpen(false)
            refreshData();
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   useEffect(() => {
      phone && setFormValues(prev => ({ ...prev, telefono: phone?.ws_p_telefono, rut: phone?.rutClient, nombre: phone?.nameRzClient }))
   }, [phone])


   const handleAvailabilityChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(parseInt(event.target.value))
    }

   const bodyModal = (
      <Box className='Modal'>
         <Box>
            <form onSubmit={handleSubmitForm}>
               <Grid container flexDirection={'row'} spacing={1}>
                  <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>Nuevo Contacto</Typography>
                  <Grid item xs={12}>
                     <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Tipo:</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="controlled-radio-buttons-group"
                           value={value}
                           onChange={handleAvailabilityChange}
                        >
                           <FormControlLabel
                              value={1}
                              control={
                                 <Radio
                                    sx={{
                                       color: '#2bb34a;',
                                       '&.Mui-checked': {
                                          color: '#2bb34a;',
                                       }
                                    }}
                                 />}
                              label="Persona" />
                           <FormControlLabel
                              value={2}
                              control={
                                 <Radio
                                    sx={{
                                       color: '#2bb34a;',
                                       '&.Mui-checked': {
                                          color: '#2bb34a;',
                                       }
                                    }}
                                 />}
                              label="Empresa" />
                        </RadioGroup>
                     </FormControl>
                  </Grid>

                  { value === 2 && <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Nombre Rz*</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        // error={error === "rut" ? true : false}
                        // helperText={error === "rut" ? "Rut es es un campo requerido" : ""}
                        id='nombre_rz'
                        name='nombre_rz'
                        required={value === 2 ? true : false}
                        placeholder="Ingrese Nombre Rz*"
                        fullWidth
                        value={nombre_rz}
                        onChange={handleInputChange}
                     />
                  </Grid>}
                 { value === 2 && <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Nombre Fantasia</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        id='nombre_f'
                        name='nombre_f'
                        placeholder="Ingrese Nombre Fantasia"
                        fullWidth
                        value={nombre_f}
                        onChange={handleInputChange}
                     />
                  </Grid>}

                  <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >{value === 1 ? 'Rut*' : 'Rut empresa*'}</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        id='rut'
                        name='rut'
                        required
                        placeholder={value === 1 ? 'Ingrese el rut*' : 'Ingrese el rut empresa*'}
                        fullWidth
                        value={rut}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >{value === 1 ? 'Nombre*':'Nombre contacto*'}</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        required
                        id='nombre'
                        name='nombre'
                        placeholder={value === 1 ? 'Ingrese Nombre*':'Ingrese Nombre contacto*'}
                        fullWidth
                        value={nombre}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Celular*</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        id='celular'
                        name='celular'
                        placeholder="Ingrese celular*"
                        required
                        fullWidth
                        value={celular}
                        onChange={handleInputChange}
                        InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   +
                               </InputAdornment>
                           ),
                       }}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} container alignContent={'end'} sx={{ mb: 1 }}>
                  <InputLabel >Correo</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        id='correo'
                        name='correo'
                        placeholder="Ingrese correo"
                        fullWidth
                        value={correo}
                        onChange={handleInputChange}
                     />
                  </Grid>

               </Grid>
               <Grid item container
                  direction="row"
                  justifyContent="end"
                  alignItems="center">
                  <Button
                     onClick={() => {
                        setFormValues(initialFormValues)
                        setOpen(false)
                     }}
                     variant="contained"
                     color="error"
                     startIcon={<CancelIcon />}
                     sx={{
                        background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                     }}>
                     <span>{'Cancelar'}</span>
                  </Button>
                  <Button
                     variant='contained'
                     type='submit'
                     startIcon={<SaveIcon />}
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     <span>{'Guardar'}</span>
                  </Button>
               </Grid>
            </form>
         </Box>
      </Box>
   )
   return (
      <div>
         <Modal
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            {bodyModal}
         </Modal>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </div>
   );
}
