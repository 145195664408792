import React, { useState } from "react";
import Avatar from "./Avatar";

interface ChatUser {
   id: number,
   id_ws_prospect?: number,
   ws_p_telefono: string,
   idchat_type?: number,
   chatTypeName?: string,
   diffHrs?: string | null,
   status?: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
 }
interface ChatListItemsProps {
  animationDelay: number;
  active?: string;
  image?: string;
  isOnline: boolean;
  name: string;
  phone:string;
  chat: ChatUser;
  handleClickChat : (chat: ChatUser) => Promise<void>;
}

const ChatListItems: React.FC<ChatListItemsProps> = ({
  animationDelay,
  active = "",
  image = "http://placehold.it/80x80",
  isOnline,
  name,
  phone,
  chat,
  handleClickChat
}) => {

  return (
    <div
      style={{ animationDelay: `0.${animationDelay}s` }}
      onClick={() => { handleClickChat(chat) }}
      className={`chatlist__item ${active ? "active" : ""}`}
    >
      <Avatar image={image} isOnline={isOnline} />

      <div className="userMeta">
        <p>{name}</p>
        <p>{phone}</p>
        {/* <span className="activeTime">32 mins ago</span> */}
      </div>
    </div>
  );
};

export default ChatListItems;
