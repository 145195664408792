import { http } from '../http/http';
import { API_URL_BASE, API_URL_SOFTNET } from '@toolbox/defaults/app';
import { CampaingDTO } from '../http/dto/CampaingDTO';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const campaingRepository = {
   getCampaing: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const campaing = await http.get<CampaingDTO>(`${API_URL_BASE}/v1/campaign?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
     // console.log(campaing)
      const { data, error, message } = campaing;
      //console.log(data)
      return {
         data,
         error,
         message
      }
   },
   getCampaingById: async ($IDCAMPANA): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const campaing = await http.get<any>(`${API_URL_BASE}/v1/campaign/${$IDCAMPANA}?id_empresa=${id_empresa}`)
      return campaing;
   },

   getGeneralCampaign: async (idstype: string, term: string): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const campaing = await http.get<any>(`${API_URL_BASE}/v1/campaign/general?idempresa=${id_empresa}&idstype=${idstype}&term=${term}`)
      return campaing;
   },


   getDataCampaing: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const campaing = await http.get<CampaingDTO>(`${API_URL_BASE}/v1/campaign/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)

      const { data, error, message } = campaing;

      return {
         data,
         error,
         message
      }
   },
   getDataCampaingDetails: async (id): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const campaing = await http.get<any>(`${API_URL_BASE}/v1/campaign/detalle/id?id=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)

      const { data, error, message } = campaing;

      return {
         data,
         error,
         message
      }
   },


   getDataCampaingPipelines: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const campaing = await http.get<any>(`${API_URL_BASE}/v1/campaign/pipelines/etapa?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = campaing;
      return {
         data,
         error,
         message
      }
   },

   createCampaign: async (dataCampaign): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign`, {
         nombre: dataCampaign.nombre,
         fecha_inicio: dataCampaign.fechaapertura,
         cantidad: parseInt(dataCampaign.cantidad),
         meta: '37%',
         idregla: dataCampaign.regla,
         utilidad_estimada: parseInt(dataCampaign.utilidad),
         productos_estimados: dataCampaign.productos,
         fecha_cierre: dataCampaign.fechacierre,
         rut_propietario: dataCampaign.propietario,
         array_rut: dataCampaign.array_rut,
         id_empresa: id_empresa,
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   createDetailsCampaignLeads: async (idcampananegocio,name,form_cliente): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailNegocioContacto`, {
         idcampananegocio: name,
         idparameter: idcampananegocio,
         form_cliente: form_cliente
      })
      return resp

   },

   saveContactGeneral: async (data): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campanaGeneral/contact`, {
         idempresa: id_empresa,
         ...data
      })
      return resp
   },

   getDetailsCampaignLeads: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailNegocioContacto?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp

   },
   getInputsDetailsCampaignLeads: async (data): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailNegocioContacto/formcliente?form_cliente=${data.form_cliente}&idcampananegocio=${data.idcampananegocio}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   updateDetailsCampaignLeadsUser: async (id: number, usuario): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/detailNegocioContacto/${id}`,{
         idusuario: usuario
      })
      return resp

   },
   updateDetailsCampaignLeadsPipelines: async (id: number, pipelines): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/detailNegocioContacto/${id}`,{
         idpipelines: pipelines.pipeline,
         precio_venta: pipelines.precio,
         cantidad_venta: pipelines.cantidad,
      })
      return resp

   },


   getIdParameter: async (id: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailNegocioContacto/parameter?idcampana=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp

   },

   updateCampaign: async (id: number, dataCampaign): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campaign/${id}`, {
         nombre: dataCampaign.nombre,
         cantidad: parseInt(dataCampaign.cantidad),
         meta: '37%',
         utilidad_estimada: parseInt(dataCampaign.utilidad),
         productos_estimados: parseInt(dataCampaign.productos),
         fecha_inicio: dataCampaign.fechaapertura,
         fecha_cierre: dataCampaign.fechacierre,

      });

      return resp;
   },

   updateUserSalesCampaign: async (idDetailCampaign: number, dataCampaign): Promise<any> => {

      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campaign/idvendedor/${idDetailCampaign}`, {
         idvendedor: dataCampaign,
         });

      return resp;
   },

   updatePipelinesDetailsCampaign: async (idDetailCampaign: number, dataCampaign): Promise<any> => {

      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campaign/idpipeline/${idDetailCampaign}`, {
         idpipelines: dataCampaign,
         //idpipelines: dataCampaign.pipeline,
         // precio_venta: dataCampaign.precio,
         // cantidad_venta: dataCampaign.cantidad,
         });

      return resp;
   },

   updatePipelinesDetailsAndPrice: async (idDetailCampaign: number, dataCampaign): Promise<any> => {

      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campaign/idpipeline/${idDetailCampaign}`, {

         idpipelines: dataCampaign.pipeline,
         precio_venta: dataCampaign.precio,
         cantidad_venta: dataCampaign.cantidad,
         });

      return resp;
   },

   deleteCampaign: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/campaign/${id}`)
      return resp;
   },
   detailsCampaignTrello: async (id): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/viewPipeline`,{
         idcampananegocio:id,
      })
      return resp
   },
   detailsCampaignLeadsTrello: async (id): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailNegocioContacto/viewPipeline`,{
         idcampananegocio:id,
      })
      return resp
   },
   generateAutomaticCampaign: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/mantencionAutomatico`,{id_empresa:id_empresa})
      return resp
   },

   detailsCampaignClient: async (id): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/clienteTareas`,{
         iddetallecampananegocioclientes:id,
      })
      return resp
   },

   changeStatusDeshabilitado: async (): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/changeStatus/deshabilitado`,{})
      return resp
   },

   searchDateCampaign: async (dataCampaign): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/searchStartDate?fecha_inicio=${dataCampaign.fecha}&tipo=${dataCampaign.tipo}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   searchResultados: async (dataCampaign): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/searchResultados?&tipo= ${dataCampaign.tipo}&word= ${dataCampaign.word}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   getClientnoCampaign: async (id: number, dataCampaign): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/getClientnoCampaign/${id}?&term= ${dataCampaign.term}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   addClientCampaign: async (dataCampaign): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/addClientCampaign`,
         dataCampaign
      )
      return resp
   },

   translateClient: async (dataCampaign): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/translateClient`,
         dataCampaign
      )
      return resp
   },

   codProduct: async (cod): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/codProduct/${cod}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },
   getCampaignTraslate: async (id: number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/getCampaignTraslate/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   getChannel: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campanaGeneral/getChannel?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   getCampanaProspecto: async (id: number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campanaGeneral/getCampanaProspecto/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },
   getCampanaGeneral: async (id: number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campanaGeneral?idcampananegocio=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },

   getDetailCampanaleadsById: async (id: number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailNegocioContacto/detalle/id?idcampananegocio=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },

   createCampanaGeneral: async (dataCampaign): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campanaGeneral`, dataCampaign)
      return resp;
   },

   updateCampanaGeneral: async (id: number, data): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campanaGeneral/${id}`,
         data
      )
      return resp
   },

   updateVendedorCampanaGeneral: async (id: number, data): Promise<any> => {
      console.log(data)
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/campanaGeneral/vendedor/${id}`,
         data
      )
      return resp
   },

   searchCodProduct: async (cod): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/codProduct2/${cod}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   createDetCampaignProduct: async (data): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/createDetCampaignProduct`, data)
      return resp;
   },

   getCountPipelines: async (id: number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campanaGeneral/listPipeline?idcampananegocio=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   dataClientesByProduct

}//dd

function dataClientesByProduct(codProduct, rango){
   const token = readLocalStorage(KEY_SOFTNET);

   return fetch(`${API_URL_SOFTNET}/informes/ventasPorMesProducto/${codProduct}`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
     body: JSON.stringify(rango),
   })
     .then(rutClients => rutClients.json())
     .then(rutClients => {
       return rutClients;
     })
     .catch(error => {
       return false;
     })
 }
