import { TemplateWsRepository } from "../repositories/TemplateWs.repository";

export const TemplateWsService= {
   createTemplate,
   getTemplateWs,
   SendTemplate,
   getUrlTemplate,
   syncTemplate,
   getTemplateWsOnVariable
};

async function createTemplate(data) {
   const resp= await TemplateWsRepository.createTemplate( data);
   return resp;
}

async function SendTemplate(data) {
   const resp= await TemplateWsRepository.SendTemplate(data);
   return resp;
}

async function getTemplateWs(term='') {
   const resp= await TemplateWsRepository.getTemplateWs(term);
   return resp;
}

async function getTemplateWsOnVariable(onVariable) {
   const resp= await TemplateWsRepository.getTemplateWsOnVariable(onVariable);
   return resp;
}
async function getUrlTemplate() {
   const resp= await TemplateWsRepository.getUrlTemplate();
   return resp;
}

async function syncTemplate() {
   const resp= await TemplateWsRepository.syncTemplate();
   return resp;
}



