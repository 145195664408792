import {
   CHATS
} from '@constants/action-type'
type ContentChats = {
   iddetail_chat: number,
   idchats: number,
   idusuario: number | null,
   idsend_type: number,
   send_date: string | null,
   name_user: string | null,
   id_ws_prospect: number,
   idchat_type: number,
   sendTypeName: string,
   message: string,
   sendHeader:  string | null,
   imgHeader:  string | null,
   sendFooter:  string | null,
   viewed: string,
   codeWS: string,
   textIdWs: string,
   textIdWsReference: string | null,
}

const initialState = {
   chats: {},
}

export default function(state = initialState, action:any){
   switch(action.type){
       case CHATS:
           return {
               ...state,
               chats: action.payload,
           }
       default: return state
   }
}
