import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputBase from '@mui/material/InputBase';
import { TemplateWsService } from '@/service/services/TemplateWs.service';
import { Grid, Tooltip, Typography } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
     backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginRight: theme.spacing(2),
   marginLeft: 0,
   width: '100%',
   [theme.breakpoints.up('sm')]: {
     marginLeft: theme.spacing(3),
     width: 'auto',
   },
 }));

 const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
 }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   '& .MuiInputBase-input': {
     padding: theme.spacing(1, 1, 1, 0),
     // vertical padding + font size from searchIcon
     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
     transition: theme.transitions.create('width'),
     width: '100%',
     [theme.breakpoints.up('md')]: {
       width: '20ch',
     },
   },
 }));


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

type Templates = {
   id: number,
   idTemplateWs: string,
   name: string,
   selectHeader: string,
   language: string,
   category: string,
   components: string,
   textHeader: string | null,
   variablesHeader: string | null,
   inputValuesHeader: string | null,
   imageHeader: string | null,
   textBody: string,
   variablesBody: string,
   inputValuesBody: string,
   textFooter: string | null,
   arrayButtons : string | null,
   idempresa: number,
   status: string | null,
   created_at: string | null,
   updated_at: string | null,
   statusWs: string
}

interface MenuOptionsProps {
   handleTemplate: (template: Templates) => void;
   open: boolean;
   disabled:boolean;
   anchorEl: any;
   handleClick: (event:any) => void;
   handleClose: () => void;
 }

const CustomizedMenus: React.FC<MenuOptionsProps> = ({
   handleTemplate,
   open,
   anchorEl,
   handleClick,
   handleClose,
   disabled
 }) => {
  const [search, setSearch] = useState<string>('');
  const [rows, setRows] = useState<Templates []>([])


   const handleSearch = (e) => {
      const { value } = e.target;
      setSearch(value);
   }

   useEffect(()=>{
      getTemplateWs()
   },[])
   // useEffect(()=>{
   //    if(search.length>3){
   //     getTemplateWs(search)
   //    }
   //    if(search.length === 0){
   //     getTemplateWs('')
   //    }
   //  },[search])
   async function getTemplateWs() {
      try {
         const resp = await TemplateWsService.getTemplateWs()
         if (resp?.code === 200) {
            resp?.data?.detail && setRows(resp?.data?.detail)
         } else {
            // setSnackBarConfig(prev => ({
            //    ...prev,
            //    open: true,
            //    severity: 'error',
            //    message: resp?.data?.message,
            // }));
         }
      } catch (e) {
         console.log(e)
      }
   }

  return (
    <div>
        <Tooltip title='Insertar tenplate'>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        disabled={disabled}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Insertar tenplate
      </Button>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem disableRipple>
          Seleccione un template
        </MenuItem> */}
        {/* <Divider sx={{ my: 0.5 }} /> */}
           {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar template"
              onChange={handleSearch}
              value={search}
              inputProps={{ 'aria-label': 'search' }}
            />
           </Search> */}
           <Grid sx={{ maxHeight: '20vh' }}>
                 {rows?.length > 0 && rows.map((item, index) => (
                  <Grid key={index} onClick={() => {handleTemplate(item)}}>
                  <Typography sx={{marginLeft:2}}>{item?.name}</Typography>
                  <Divider sx={{ my: 0.5 }} />
                  </Grid>
                 ))}
              </Grid>
      </StyledMenu>
    </div>
  );
}


export default CustomizedMenus;
