import {
   CHATS,
} from '@constants/action-type';

export function actionSetChats(data: any) {
   return {
      type: CHATS,
      payload: data
   }
}


