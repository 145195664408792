import { Typography } from "@mui/material"

export const DatosGenerales: React.FC<any> = (props): JSX.Element => {
    
    const { dataArriendo } = props;
    
    return (
        <div style={{ minHeight: "200px", background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
              <Typography variant="h4" sx={{ color: "#155172", fontWeight: "bold" }}>
                Datos Generales
              </Typography>
              <div style={{ marginTop: "20px" }}>
                <Typography>
                  <strong>Fecha de arriendo:</strong> {dataArriendo?.fecha_arriendo}
                </Typography>
                <Typography>
                  <strong>Código del arriendo:</strong> {dataArriendo?.cod_pago_mensual}
                </Typography>
                <Typography>
                  <strong>Url Documento:</strong> 
                  <a href={dataArriendo?.url_documento} style={{textDecoration: 'underline', cursor:'pointer'}} target="_blank">
                                                   click aquí
                                                </a>
                </Typography>
                <Typography>
                  <strong>Estado de la Garantia:</strong> {dataArriendo?.status_garantia_name}
                </Typography>
                <Typography>
                  <strong>Guia de despacho:</strong> {dataArriendo?.url_guia_despacho}
                </Typography>
                <Typography>
                  <strong>Observación:</strong> {dataArriendo?.observacion}
                </Typography>
                <Typography>
                  <strong>Fecha de confirmación:</strong> {dataArriendo?.fecha_confirmacion}
                </Typography>
              </div>
            </div>
    )
}