import { MenuLateral } from '@/components/common/LateralMenu';
import { Protected } from '@/components/layout/Protected';
import { configurationService } from '@/service/services/Configuration.service';
import { taskService } from '@/service/services/Task.service';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Alert, Avatar, Button, Checkbox, Container, Divider, FormControl, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemAvatar, MenuItem, Select, Snackbar, TextField, Typography, Backdrop, FormLabel, Autocomplete, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Props } from './Configuracion.type';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WebIcon from '@mui/icons-material/Web';//lading
import TelegramIcon from '@mui/icons-material/Telegram';
import WebAssetIcon from '@mui/icons-material/WebAsset';//pagina web
import TwitterIcon from '@mui/icons-material/Twitter';
import PageviewIcon from '@mui/icons-material/Pageview';//otros
import { entityService } from '@/service/services/Entity.service';
import { mailService } from '@/service/services/Mail.service';
import { SpinnerGrow } from '@/components/common/Spinner';
import { ArriendosService } from '@/service/services/Arriendos.service.';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const initialFormValues = {
   id: 0,
   idtipofeeds: 0,
   hora_incio_t1: "",
   hora_fin_t1: "",
   hora_incio_t2: "",
   hora_fin_t2: ""
}

const dt = [
   <FacebookIcon color='primary' />,
   <InstagramIcon color='warning' />,
   <TwitterIcon color='info' />,
   <WebIcon color='error' />,
   <WebAssetIcon color='secondary' />,
   <WhatsAppIcon color='success' />,
   <TelegramIcon color='info' />,
   <PageviewIcon color='action' />,
]

const dataTipoDocumento = [{ id: 1, name: 'Nota de Venta' }, { id: 2, name: 'Factura Electrónica' }]
const dataTipoAtencion = [{id:1, name:'Mesón'}, {id:2, name:'Autoatendible'}]

export const ConfiguracionView: React.FC<Props> = ({

}): JSX.Element => {
   const datoUsuario = readLocalStorage(KEY_USER_DATA)
   const rut_empresa = datoUsuario?.user?.rut_empresa;
   //const email = datoUsuario.data.email
   const [formValuesEmail, setFormValuesEmail] = useState<any>({
      correo: '',
      password_generate: '',
      status: ''
   });
   const [valuesEmail, setValuesEmail] = useState<any>([]);
   const [renderForm, setRenderForm] = useState<boolean>(false);
   const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
   const [open, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [loading1, setLoading1] = useState(false);
   const [loadingWs, setLoadingWs] = useState(false);
   const [data, setData] = useState<any>([]);
   const [dataTipoTarea, setDataTipoTarea] = useState<any>([]);
   const [formValues, setFormValues] = useState(initialFormValues);
   const { id = "", idtipofeeds = "", hora_incio_t1 = "", hora_fin_t1 = "", hora_incio_t2 = "", hora_fin_t2 = "" } = formValues;
   const [error, setError] = useState(null);
   const [errorWs, setErrorWs] = useState<string>('');
   const [checked, setChecked] = useState(false);
   const [disabled, setDisabled] = useState<boolean>(true);
   const [dataListCanal, setDataListCanal] = useState<any>([])
   const [dataSupervisor, setDataSupervisor] = useState<any>([])
   const [checkedSupervisor, setCheckedSupervisor] = useState(false);
   const [disabledSupervisor, setDisabledSupervisor] = useState<boolean>(false);
   const [list, setList] = useState([]);
   const [dataInput, setDataInput] = useState([]);
   const [listChecked, setListChecked] = useState<any>([
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true },
      { status: true }
   ])

   const [dataWs, setDataWS] = useState<any>({
      token_send_ws: '',
      version_ws: '',
      id_num_phone: '',
      id_ws_account: '',
      token_ws: '',
      url_webhooks:''
   })

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })

   const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState<any>(null)
   const [tipoAtencionSelected, setTipoAtencionSelected] = useState<any>(null)

   const actualizarConfiguracionArriendo = async () => {
      const payload = {
         idtipo_atencion: tipoAtencionSelected.id,
         idtipo_documento_facturacion: tipoDocumentoSelected.id
      }
      const response = await ArriendosService.actualizarConfiguracion(rut_empresa, payload);
      if(!!response?.status){
         setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:response?.message})
      }else{
         setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:response?.message})
      }
      await getConfiguracion();
   }
   const ListCanales = async () => {
      const resp = await configurationService.ListStatusCampaingGenerales()
      const dataList = [];
      resp.data.map((value, i) => {
         setListChecked([...listChecked, listChecked[i].status = value?.status]);
         dataList.push({ ...value, icon: dt[i] })
      })
      // console.log(dataList)
      setDataListCanal(dataList)
   }

   const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setDisabled(!event.target.checked)
      setChecked(event.target.checked);
      const estado = event.target.checked ? '1' : '0'

      const up = await configurationService.updateStatusConfigCampana(formValues.id, estado)
      getConfigCampana();
   };

   const handleChangeListChecked = async (event: React.ChangeEvent<HTMLInputElement>, i) => {
      console.log(event.target.checked);
      console.log(listChecked)
      if (event.target.checked) {
         // listChecked[i].status=true;
         setListChecked([...listChecked, listChecked[i].status = true]);
      } else {
         // listChecked[i].status=false;
         setListChecked([...listChecked, listChecked[i].status = false]);
      }
   }

   const ChangeStatus = async () => {
      setLoading(true);
      const listStatus = [];
      dataListCanal.map((value, i) => {
         listStatus.push({ idcanal: value.id, status: listChecked[i].status })
      })
      const data = JSON.stringify(listStatus).replace(/\\n/g, '');
      const res = await configurationService.changeStatusCampaingGenerales(data)
      if (res) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Actualización Exitosa',
         }));
         setLoading(false);
      }
      // changeStatusCampaingGenerales
   }

   useEffect(() => {
      // getConfiguration();
      setOpenBackdrop(true)
      getFeedsData();
      getConfigCampana();
      ListCanales();
      getSupervisor();
      statusAsignacion();
      getCorreoImap();
      getSettingWsEntity();
      setOpenBackdrop(false)
      getConfiguracion();
   }, [])

   const getSettingWsEntity = async () => {
      const resp: any = await configurationService.getSettingWsEntity();
      console.log('response1', resp)
      if (resp?.status?.code === 200) {
         resp?.data && setDataWS(resp?.data)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Error al cargar los campos de la integración con whatsapp',
         }));
      }
   }


   const getConfiguracion = async()=> {
      const resp:any = await ArriendosService.getConfiguracion(rut_empresa);
      if(!!resp){
         const findTipoAtencion = dataTipoAtencion.find(item => item.id == resp?.data?.idtipo_atencion);
         const findTipoDocumento = dataTipoDocumento.find( item => item?.id == resp?.data?.idtipo_documento_facturacion);

         setTipoAtencionSelected(findTipoAtencion);
         setTipoDocumentoSelected(findTipoDocumento);
      }
   }

   const getCorreoImap = async ()=>{
      const resp:any = await mailService.getCuentaCorreoImap();
      setValuesEmail(resp?.data);
      // setFormValuesEmail({
      //    correo: resp?.data?.correo,
      //    password_generate: resp?.data?.password_generate,
      //    status : resp?.data?.status
      // })
   }

   async function statusAsignacion() {
      try {
         const resp = await configurationService.statusAsignacion()
         if (resp) {
            console.log(resp.data.status)
            setCheckedSupervisor(resp.data.status)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const getSupervisor = async () => {
      list.length = 0
      const resp = await entityService.getSupervisor()
      if (resp) {
         setDataSupervisor(resp.data)
         resp.data.map((row) => {
            list.push({ [`name`]: `${row.idusuario}`, ['value']: row.participacion });
         })
      }
   }

   async function updateConfiguration() {
      setLoading1(true)
      const up = await configurationService.updateConfigCampana(formValues.id, formValues)
      if (up.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Actualización Exitosa',
         }));
      }
      setLoading1(false)
   }

   const handleFormValues = (e) => {
      const changedFormValues = {
         ...data,
         [e.target.name]: e.target.value
      }
      setData(changedFormValues)
   }

   const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // updateConfiguration()
      //setOpen(true)
   }

   const handleSubmitFormTasksforCampaign = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateConfiguration()
      //const
   }
   //para campañas automaticas

   async function getFeedsData() {

      const resp = await taskService.getFeedsData()

      if (resp.data) {

         setDataTipoTarea(resp.data ? resp.data.tipofeeds : []);
      }

   }
   async function getConfigCampana() {
      const resp = await configurationService.getConfigCampana();

      if (resp.data.length > 0) {
         setFormValues(resp.data[0])

         if (resp.data[0].status == '1') {
            setDisabled(false)
            setChecked(true)
         } else {
            setDisabled(true)
            setChecked(false)
         }
      }
   }

   const handleFormValuesSupervisor = (e, i) => {
      switch (e.target.name) {
         case list[i].name:
            setDataInput([...list, list[i].value = e.target.value]);
            break;
      }
   }

   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }

      idtipofeeds !== null && setError("");
      setFormValues(changedFormValues);
   }

   const handleSubmitSupervisor = async (e) => {
      e.preventDefault();
      if (checkedSupervisor) {
         const dataEnvio = {
            automatico: 1,
            lectura_participantes: false
         }
         const resp = await configurationService.asignacionSupervisor(dataEnvio)
         if (resp.data) {
            const status = await configurationService.changeAsignacion('1')
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: 'Se actualizaron las participaciones',
            }));
         }
      } else {
         const ArrayNewParticipacion = [];
         let Porcentaje = 0;
         dataSupervisor.map((row, i) => {
            Porcentaje = Porcentaje + parseInt(list[i].value);
            ArrayNewParticipacion.push(list[i]);
         })
         if (Porcentaje == 100) {
            const dataEnvio = {
               automatico: 0,
               lectura_participantes: false,
               supervisores: list
            }
            const resp = await configurationService.asignacionSupervisor(dataEnvio)
            if (resp.data) {
               const status = await configurationService.changeAsignacion('0')
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'success',
                  message: 'Se actualizaron las participaciones',
               }));
            }
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: 'La suma de los procentajes deben ser igual a 100%',
            }));
         }
      }
   }

   const handleSubmitConfigWS = async (e) => {
      e.preventDefault();
      if (!dataWs?.token_send_ws.trim()) {
         return setErrorWs("token_send_ws");
      }
      if (!dataWs?.version_ws.trim()) {
         return setErrorWs("version_ws");
      }
      if (!dataWs?.id_num_phone.trim()) {
         return setErrorWs("id_num_phone");
      }
      if (!dataWs?.id_ws_account.trim()) {
         return setErrorWs("id_ws_account");
      }
      if (!dataWs?.token_ws.trim()) {
         return setErrorWs("token_ws");
      }

      const response = await configurationService.updateSettingWsEntity(dataWs);
      console.log('response', response)
      if (response?.status?.code === 200) {
         if (response.data) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: 'Datos guardado con éxito',
            }));
         }
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Error al guardar la data',
         }));
      }

   }

   const onChangeConfigWS = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      switch (name) {
         case 'token_send_ws':
            setDataWS(prev => ({ ...prev, token_send_ws: value }))
            break;
         case 'version_ws':
            setDataWS(prev => ({ ...prev, version_ws: value }))
            break;
         case 'id_num_phone':
            setDataWS(prev => ({ ...prev, id_num_phone: value }))
            break;
            case 'id_ws_account':
               setDataWS(prev => ({ ...prev, id_ws_account: value }))
               break;
         case 'token_ws':
            setDataWS(prev => ({ ...prev, token_ws: value }))
            break;

         default:
            break;
      }
   }

   const handleChangeSupervisor = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setDisabledSupervisor(event.target.checked)
      setCheckedSupervisor(event.target.checked);
      const estado = event.target.checked ? '1' : '0'
      getConfigSupervisor(estado);
   };

   const onChangeValuesEmail = (e) => {
      const name = e.target.name;

      const mailName = 'correo';
      const passwordName = 'password';
      const hostName = 'host';
      const puertoName = 'puerto';

      if (name.indexOf(mailName) > 0) {
         const id = name.slice(7);

         valuesEmail.map((value, i) => {
            if (value.id == id) {
               const valueEmail = {
                  ...value, correo: e.target.value
               }

               valuesEmail.splice(i, 1, valueEmail)
               setValuesEmail(valuesEmail)
               return setRenderForm(!renderForm)
            }
         })
      }

      if (name.indexOf(passwordName) > 0) {
         const id = name.slice(9);
         valuesEmail.map((value, i) => {
            if (value.id == id) {
               const valueEmail = {
                  ...value, password_generate: e.target.value
               }
               valuesEmail.splice(i, 1, valueEmail)
               setValuesEmail(valuesEmail)
               return setRenderForm(!renderForm)
            }
         })
      }

      if (name.indexOf(hostName) > 0) {
         const id = name.slice(5);
         valuesEmail.map((value, i) => {
            if (value.id == id) {
               const valueEmail = {
                  ...value, host: e.target.value
               }
               valuesEmail.splice(i, 1, valueEmail)
               setValuesEmail(valuesEmail)
               return setRenderForm(!renderForm)
            }
         })
      }
      if (name.indexOf(puertoName) > 0) {
         const id = name.slice(7);
         valuesEmail.map((value, i) => {
            if (value.id == id) {
               const valueEmail = {
                  ...value, puerto: e.target.value
               }
               valuesEmail.splice(i, 1, valueEmail)
               setValuesEmail(valuesEmail)
               return setRenderForm(!renderForm)
            }
         })
      }

      // setError('')
      // switch (e.target.name) {
      //    case 'correo':
      //       setFormValuesEmail({...formValuesEmail, correo: e.target.value})
      //      break;
      //    case 'password_generate':
      //       setFormValuesEmail({...formValuesEmail, password_generate: e.target.value})
      //      break;
      //  }
   }

   const handleSubmitVincular = async (id) => {
      try {

         const data = valuesEmail.filter((value) => { return value.id == id });
         console.log(data)
         const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
         if (!emailRegex.test(data[0]?.correo)) {
            console.log(data[0]?.correo)
            return setError('invalido' + id)
         }

         const resp: any = await mailService.getCreateOrUpdateCuentaCorreoImap(data[0]);
         if (resp?.data == 'Conexión rechazada') {
            getCorreoImap();
            return setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'warning', message: resp.data })
         }

         if (resp?.data) {
            setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'success', message: 'Actualizado con éxito' })
         }

      } catch (e) {

         return setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'warning', message: 'Ocurrió un error' })

      }
   }

   const getConfigSupervisor = async (estado) => {
      //0 Manual 1 automatico
      if (estado == 1) { //1
         list.length = 0
         console.log(list)
         const dataEstado = {
            automatico: estado,
            lectura_participantes: true
         }
         const resp = await configurationService.asignacionSupervisor(dataEstado)
         setDataSupervisor(resp.data.details)
         resp.data.details.map((row) => {
            list.push({ [`name`]: `${row.idusuario}`, ['value']: row.participacion });
         })
         console.log(list)
      } else {
         getSupervisor()
      }
   }

   const cuerpoVinculaCorreo =
      <>
         {valuesEmail.map((value) => {
            return (
               <>
                  <Grid item xs={12}><span>{value?.usuario?.nombre} :</span></Grid>
                  <Grid item xs={12}>
                     <TextField
                        type='email'
                        name={'*correo' + value.id}
                        value={value?.correo}
                        placeholder='Ingrese su correo electrónico'
                        sx={{ width: '40%', mr: 2 }}
                        size='small'
                        onChange={(e) => { onChangeValuesEmail(e) }}
                        error={error == 'invalido' + id ? true : false}
                        helperText={error == 'invalido' + id ? 'Correo inválido' : ''}
                        fullWidth
                     />
                     <TextField
                        type='input'
                        name={'*password' + value.id}
                        placeholder='Ingresa tu contraseña generada'
                        fullWidth
                        value={value?.password_generate}
                        onChange={(e) => { onChangeValuesEmail(e) }}
                        sx={{ width: '40%', mr: 2 }}
                        size='small'
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <TextField
                        type='email'
                        name={'*host' + value.id}
                        value={value?.host}
                        placeholder='Ingrese su host o dominio'
                        sx={{ width: '40%', mr: 2 }}
                        size='small'
                        onChange={(e) => { onChangeValuesEmail(e) }}
                        error={error == 'invalido' + id ? true : false}
                        helperText={error == 'invalido' + id ? 'Correo inválido' : ''}
                        fullWidth
                     />
                     <TextField
                        type='input'
                        name={'*puerto' + value.id}
                        placeholder='Ingresa el puerto de entrada de correos'
                        fullWidth
                        value={value?.puerto}
                        onChange={(e) => { onChangeValuesEmail(e) }}
                        sx={{ width: '40%', mr: 2 }}
                        size='small'
                     />
                     <Button
                        sx={{
                           background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                              bgcolor: '#E4980D'
                           }
                        }}
                        variant='contained'
                        onClick={() => { handleSubmitVincular(value?.id) }}
                     >{formValuesEmail.status == "0" ? 'Vincular' : 'Actualizar'}
                     </Button>
                  </Grid>
               </>
            )
         })}
      </>

   return (
      <Protected>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               <Grid item md={3} container>
                  <MenuLateral />
               </Grid>
               <Grid item md={9}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #3F9BCC ', mt: 3 }}
               >
                  <Snackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                     <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                        Hora de Sincronización ha sido actualizada!
                     </Alert>
                  </Snackbar>
                  <Grid sx={{ mt: 1, ml: 2 }}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>Configuración</Typography>
                  </Grid>
                  <form onSubmit={handleSubmitForm}>
                     {/* <Grid container spacing={1} sx={{mt:2, ml:3}}>
                  <Grid item xs={5}>
                      <Typography sx={{fontSize:'1.3em'}}>Hora de Sincronización :</Typography>
                  </Grid>
                  <Grid item md={4} >
                  <TextField
                  fullWidth
                  size="small"
                  id="sync_time"
                  label=""
                  name="sync_time"
                  type="time"
                  value={data.sync_time}
                  onChange={handleFormValues}
                  />
                  </Grid>
                  <Grid item md={2} container justifyContent='end'>
                     <Button
                     sx={{background:'#bf6c00', color:'#fff', '&:hover':{
                        bgcolor:'#bf6c00'
                       }}}
                     type='submit'>ACTUALIZAR</Button>
                  </Grid>
               </Grid> */}
                  </form>
                 { rut_empresa != '50290070-6' &&
                  <>
                   <Divider sx={{ mt: 2 }} />
                  <form onSubmit={handleSubmitFormTasksforCampaign}>
                     <Grid container spacing={2} sx={{ mt: 1, ml: 2 }}>
                        <Grid item xs={9}>
                           <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Creación automática de tareas :</span>
                        </Grid>
                        <Grid item xs={1} >
                           <FormControlLabel
                              control={<Switch
                                 checked={checked}
                                 onChange={handleChange}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />}
                              label={checked ? "Habilitado" : "Desahibilitado"}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <FormControl fullWidth error={error === "idtipofeeds" ? true : false}>
                              <InputLabel id="tipo">Tipo</InputLabel>
                              <Select
                                 labelId="tipo"
                                 id="tipo"
                                 sx={{ bgcolor: '#fff' }}
                                 size="small"
                                 fullWidth
                                 disabled={disabled}
                                 name="idtipofeeds"
                                 value={idtipofeeds}
                                 label="Tipo"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    formValues.idtipofeeds == 1 ?
                                       <InputAdornment position='start'>
                                          <PhoneIcon />
                                       </InputAdornment>
                                       : formValues.idtipofeeds == 2 ?
                                          <InputAdornment position='start'>
                                             <EmailIcon />
                                          </InputAdornment>
                                          : formValues.idtipofeeds == 3 ?
                                             <InputAdornment position='start'>
                                                <VideocamIcon />
                                             </InputAdornment> :
                                             <InputAdornment position='start'>
                                                <AssignmentIcon />
                                             </InputAdornment>
                                 }
                              >
                                 {dataTipoTarea.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idtipofeeds" ? "Tipo Tarea es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item md={5}>
                           <Typography>Turno 1</Typography>
                        </Grid>
                        <Grid item md={7}>
                           <Typography>Turno 2</Typography>
                        </Grid>
                        <Grid item md={2} >
                           <TextField
                              fullWidth
                              size="small"
                              id="hora_incio_t1"
                              disabled={disabled}
                              label=""
                              name="hora_incio_t1"
                              type="time"
                              value={hora_incio_t1}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item md={2} >
                           <TextField
                              fullWidth
                              size="small"
                              id="hora_fin_t1"
                              disabled={disabled}
                              label=""
                              name="hora_fin_t1"
                              type="time"
                              value={hora_fin_t1}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={2} >
                           <TextField
                              fullWidth
                              size="small"
                              id="hora_incio_t2"
                              disabled={disabled}
                              label=""
                              name="hora_incio_t2"
                              type="time"
                              value={hora_incio_t2}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item md={2} >
                           <TextField
                              fullWidth
                              size="small"
                              id="hora_fin_t2"
                              disabled={disabled}
                              label=""
                              name="hora_fin_t2"
                              type="time"
                              value={hora_fin_t2}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item md={2} container justifyContent='end'>
                           <Box sx={{ m: 1, position: 'relative' }}>
                              <Button
                                 sx={{
                                    background: '#bf6c00', color: '#fff', fontWeight: '700', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                                 disabled={loading1}
                                 type='submit'>
                                 ACTUALIZAR
                              </Button>
                              {loading1 && (
                                 <CircularProgress
                                    size={24}
                                    sx={{
                                       position: 'absolute',
                                       top: '50%',
                                       left: '50%',
                                       marginTop: '-12px',
                                       marginLeft: '-12px',
                                    }}
                                 />
                              )}
                           </Box>
                        </Grid>
                     </Grid>
                  </form></>
                 }
                 { rut_empresa != '50290070-6' &&

                   <div style={{ marginTop: '10px'}}>
                     <Divider sx={{mt:4}}/>
                     <Grid container sx={{mt:4, px:4}} spacing={2}>
                        <Grid item xs={12} container justifyContent={'space-between'}>
                           <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Vincula tu correo gmail:</span>
                           {/* <a href='https://drive.google.com/file/d/1M6J0s-7nIu26whZBvlQ0MqklNps_7PGw/view?usp=sharing' style={{textDecoration:'none'}}>
                           <Button>Ver Manual</Button>
                        </a> */}
                        </Grid>
                        {renderForm ? cuerpoVinculaCorreo : cuerpoVinculaCorreo}

                        <Grid item xs={12} ml={2}>
                           <p style={{ color: '#E4980D', fontSize: '1em', marginRight: '20px' }}>{`(*) En caso de no tener una contraseña generada para aplicación de terceros, dale click `}<a href='https://drive.google.com/file/d/1M6J0s-7nIu26whZBvlQ0MqklNps_7PGw/view?usp=sharing' target='_blank'>aquí</a> {', y sigue los pasos del manual.'}</p>
                           <p style={{ color: '#E4980D', fontSize: '1em', marginRight: '20px' }}>{`(*) En caso de usar un servidor de correo distinto tanto gmail personal o empresarial, es necesario especificar el host, username, password y puerto`}</p>
                        </Grid>
                     </Grid>
                  </div>
                  }
                 { rut_empresa != '50290070-6' &&

                  <div style={{ marginTop: '10px' }}>
                     <Divider sx={{ mt: 4 }} />
                     <Grid container sx={{ mt: 4, px: 4 }} justifyContent='space-between'>
                        <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Campañas generales por canal</span>
                        <Box sx={{ m: 1, position: 'relative' }}>
                           <Button
                              sx={{
                                 background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                                    bgcolor: '#E4980D'
                                 }
                              }}
                              variant='contained'
                              disabled={loading}
                              onClick={() => { ChangeStatus() }}>
                              Guardar
                           </Button>
                           {loading && (
                              <CircularProgress
                                 size={24}
                                 sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                 }}
                              />
                           )}
                        </Box>
                     </Grid>
                     <Grid item container direction='row' justifyContent='center' sx={{ mt: 1, ml: 4 }} >
                        {
                           dataListCanal.map((value, i) => {
                              return (
                                 <Grid item container xs={6} direction='row' justifyContent='center' sx={{ pt: 2 }}>
                                    <Grid item container alignItems="center" xs={6}>
                                       {value.icon}
                                       <span style={{ fontSize: '20px', marginLeft: '5px' }}>{value.canal}:</span>
                                    </Grid>
                                    <Grid item xs={6} >
                                       <FormControlLabel
                                          control={<Switch
                                             checked={listChecked[i].status}
                                             onChange={(e) => { handleChangeListChecked(e, i) }}
                                             inputProps={{ 'aria-label': 'controlled' }}
                                          />}
                                          label={listChecked[i].status ? "" : ""}
                                       />
                                    </Grid>
                                 </Grid>
                              )
                           })
                        }
                     </Grid>
                  </div>
}
{ rut_empresa != '50290070-6' &&
<>
                  <Divider sx={{ mt: 4 }} />
                  <form
                     onSubmit={handleSubmitSupervisor}
                  >
                     <Grid container spacing={2} sx={{ mt: 1, ml: 2 }}>
                        <Grid item xs={9}>
                           <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Configuración de supervisores: </span>
                        </Grid>
                        <Grid item xs={1} >
                           <FormControlLabel
                              control={<Switch
                                 checked={checkedSupervisor}
                                 onChange={handleChangeSupervisor}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />}
                              label={checkedSupervisor ? "Automático" : "Manual"}
                           />
                        </Grid>
                        <Grid item xs={12} ml={2}>
                           <p style={{ color: '#E4980D', fontSize: '1em', marginRight: '20px' }}>{`(*) Solo serán listados y configurables los supervisores que hayan confirmado su usuario.`} </p>
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' sx={{ mt: 1, ml: 4 }} >
                           {
                              dataSupervisor.map((value, i) => {
                                 return (
                                    <Grid item container xs={6} direction='row' justifyContent='center' sx={{ pt: 2 }}>
                                       <Grid item container alignItems="center" xs={6}>
                                          <span style={{ fontSize: '20px', marginLeft: '5px' }}>{value.nombre}:</span>
                                       </Grid>
                                       <Grid item xs={5} mr={4}>
                                          <TextField
                                             margin="normal"
                                             required
                                             fullWidth
                                             disabled={disabledSupervisor}
                                             maxRows={4}
                                             name={list[i] ? list[i].name : `${value.idusuario}`}
                                             value={list[i] ? list[i].value : value.participacion}
                                             sx={{ bgcolor: '#E3F4FD' }}
                                             onChange={(e) => { handleFormValuesSupervisor(e, i) }}
                                             InputProps={{
                                                inputProps: { min: 1 }
                                             }}
                                             type="number"
                                             size="small"
                                          />
                                       </Grid>
                                    </Grid>
                                 )
                              })
                           }
                        </Grid>
                        <Grid item md={2} container >
                           <Box sx={{ m: 1, position: 'relative' }}>
                              <Button
                                 sx={{
                                    background: '#bf6c00', color: '#fff', fontWeight: '700', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                                 disabled={loading1}
                                 type='submit'>
                                 ACTUALIZAR
                              </Button>
                              {loading1 && (
                                 <CircularProgress
                                    size={24}
                                    sx={{
                                       position: 'absolute',
                                       top: '50%',
                                       left: '50%',
                                       marginTop: '-12px',
                                       marginLeft: '-12px',
                                    }}
                                 />
                              )}
                           </Box>
                        </Grid>
                     </Grid>
                  </form>
                  </>}
                  <Divider sx={{ mt: 4 }} />
                  <Grid container spacing={2} sx={{ mt: 1, ml: 2, minHeight: 300 }}>
                     <Grid item xs={9}>
                        <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Configuración de arriendos: </span>
                     </Grid>
                     <Grid item xs={9}>
                        <FormLabel>¿Cual será el tipo de atención? </FormLabel>
                        <Autocomplete
                           disablePortal
                           id="combo-box-company"
                           value={tipoAtencionSelected}
                           options={dataTipoAtencion || []}
                           getOptionLabel={(option) => option.name || ""}
                           isOptionEqualToValue={(option, value) => option?.id === value?.id}
                           fullWidth
                           renderInput={(params) => <TextField {...params} size="small" />}
                           onChange={(e, newValue) => {
                              setTipoAtencionSelected(newValue);
                           }}
                        />
                     </Grid>
                     <Grid item xs={9}>
                        <FormLabel>¿Qué documento utilizará para el cobro mensual? </FormLabel>
                        <Autocomplete
                           disablePortal
                           id="combo-box-company"
                           value={tipoDocumentoSelected}
                           options={dataTipoDocumento || []}
                           getOptionLabel={(option) => option.name || ""}
                           isOptionEqualToValue={(option, value) => option?.id === value?.id}
                           fullWidth
                           renderInput={(params) => <TextField {...params} size="small" />}
                           onChange={(e, newValue) => {
                              setTipoDocumentoSelected(newValue);
                           }}
                        />
                     </Grid>
                     <Grid item xs={3}></Grid>
                     <Grid item xs={2}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                           <Button
                              sx={{
                                 background: '#bf6c00', color: '#fff', fontWeight: '700', '&:hover': {
                                    bgcolor: '#bf6c00'
                                 }
                              }}
                              disabled={loading1}
                              onClick={()=>actualizarConfiguracionArriendo()}>
                              ACTUALIZAR
                           </Button>
                           {loading1 && (
                              <CircularProgress
                                 size={24}
                                 sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                 }}
                              />
                           )}
                        </Box>
                     </Grid>
                  </Grid>
                  <Divider sx={{ mt: 4 }} />
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                     >
                        <span style={{ color: '#155172', fontSize: '1.5em', marginRight: '20px' }}>Integración Whatsapp Business: </span>
                     </AccordionSummary>
                     <AccordionDetails>
                        <form
                           onSubmit={handleSubmitConfigWS}
                        >
                           <Grid container spacing={2} sx={{ ml: 2 }}>
                              <Grid item container direction='column' justifyContent='center' sx={{ mt: 1, mr: 5 }} spacing={2}>
                                 <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`Token de whatsapp api`} </p>
                                 <TextField
                                    multiline
                                    required
                                    name={'token_send_ws'}
                                    value={dataWs?.token_send_ws}
                                    placeholder='Ingrese token de whatsapp api'
                                    size='small'
                                    onChange={onChangeConfigWS}
                                    // error={errorWs == 'token_send_ws'? true : false}
                                    // helperText={errorWs == 'token_send_ws'? 'token inválido' : ''}
                                    fullWidth
                                 />
                                 <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`Versión de WsApi`} </p>
                                 <TextField
                                    required
                                    name={'version_ws'}
                                    value={dataWs?.version_ws}
                                    placeholder='Versión de WsApi'
                                    size='small'
                                    onChange={onChangeConfigWS}
                                    // error={errorWs == 'version_ws'? true : false}
                                    // helperText={errorWs == 'version_ws'? 'versión inválido' : ''}
                                    fullWidth
                                 />
                                 <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`Identificador de número de teléfono`} </p>

                                 <TextField
                                    required
                                    name={'id_num_phone'}
                                    value={dataWs?.id_num_phone}
                                    placeholder='Identificador de número de teléfono'
                                    size='small'
                                    onChange={onChangeConfigWS}
                                    // error={errorWs == 'id_num_phone'? true : false}
                                    // helperText={errorWs == 'id_num_phone'? 'Identificador inválido' : ''}
                                    fullWidth
                                 />

                                 <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`Identificador de la cuenta de WhatsApp Business`} </p>

                                 <TextField
                                    required
                                    name={'id_ws_account'}
                                    value={dataWs?.id_ws_account}
                                    placeholder='Identificador de la cuenta de WhatsApp Business'
                                    size='small'
                                    onChange={onChangeConfigWS}
                                    // error={errorWs == 'id_ws_account'? true : false}
                                    // helperText={errorWs == 'id_ws_account'? 'Identificador inválido' : ''}
                                    fullWidth
                                 />
                                    <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`URL de devolución de llamada (webhook)`} </p>
                                 <TextField
                                    required
                                    multiline
                                    name={'token_ws'}
                                    value={dataWs?.url_webhooks}
                                    size='small'
                                    fullWidth
                                 />
                                 <p style={{ color: '#E4980D', fontSize: '1.2em', marginTop: '20px' }}>{`Token de verificación webhook`} </p>
                                 <TextField
                                    required
                                    multiline
                                    name={'token_ws'}
                                    value={dataWs?.token_ws}
                                    placeholder='Token de verificación webhook'
                                    size='small'
                                    // onChange={onChangeConfigWS}
                                    // error={errorWs == 'token_ws'? true : false}
                                    // helperText={errorWs == 'token_ws'? 'versión inválido' : ''}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid item md={2} container >
                                 <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                       sx={{
                                          background: '#bf6c00', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#bf6c00'
                                          }
                                       }}
                                       disabled={loadingWs}
                                       type='submit'>
                                       Guardar
                                    </Button>
                                    {loadingWs && (
                                       <CircularProgress
                                          size={24}
                                          sx={{
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: '-12px',
                                             marginLeft: '-12px',
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Grid>
                           </Grid>
                        </form>

                     </AccordionDetails>
                  </Accordion>
               </Grid>
            </Grid>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={openBackdrop}
            >
               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow />
                  <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
               </Grid>
            </Backdrop>
         </Container>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </Protected>

   )
}
