import { SpinnerGrow } from "@/components/common/Spinner"
import { Protected } from "@/components/layout/Protected"
import { Alert, Backdrop, Button, Container, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import excelImage from '../../../assets/fileIcons/xlsx.png';
import '../../../assets/styles/arriendos-app.css'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { TableData } from "@/components/common/Table";

const header = [
    { name: 'rut_cliente', label: 'RUT CLIENTE', width: '' },
    { name: 'cliente', label: 'CLIENTE', width: '' },
    { name: 'n_factura', label: 'N° PRIMERA FACTURA', width: '' },
    { name: 'fecha_inicio', label: 'FECHA DE INICIO', width: '' },
    { name: 'codigo_producto', label: 'CÓDIGO SERVICIO', width: '' },
    { name: 'precio_diario', label: 'PRECIO DIARIO', width: '', money: true},
    { name: 'precio_mensual', label: 'PRECIO MENSUAL', width: '', money: true},
    { name: 'cod_garantia', label: 'CÓDIGO DE PAGO GARANTIA', width: '' },
    { name: 'way_payment', label: 'FORMA DE PAGO', width: '' },
    { name: 'total_garantia', label: 'TOTAL GARANTIA ARRIENDO', width: '', money: true },
    { name: 'posible_devolucion', label: 'POSIBLE DEVOLUCIÓN', width: '' },
    { name: 'n_ultima_factura', label: 'ÚLTIMA FACTURA', width: '' },
    { name: 'fecha_ultima_factura', label: 'FECHA ÚLTIMA FACTURA', width: '' },
 ];

export const OnBoarding: React.FC<any> = ({ }): JSX.Element => {

    const userData = readLocalStorage(KEY_USER_DATA);
    const rutEmpresa = userData?.user?.rut_empresa;
    const fileInputRef = useRef(null);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })

  const [Total, setTotal] = useState<any>(0)


    const [open, setOpen] = useState<boolean>(false);
    const [valueDoc, setValueDoc] = useState<any>(null);
    const [dataProcess, setDataProcess] = useState<any>(null);
    const handleButtonClickDoc = () => {
        fileInputRef.current.click();
    }

    useEffect(() => {
        if(!!valueDoc) {
            processTemplate();
        }
    },[valueDoc])

    console.log(valueDoc)

    const processTemplate = async() => {
        setOpen(true);
        const formdata = new FormData();
        formdata.append('excel', valueDoc);
        const response = await ArriendosService.processtemplateOnBoarding(formdata);
        if (!!response?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })
            console.log(response?.data)
            setDataProcess(response?.data)
        }else{
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
            setValueDoc(null)
        }
        setOpen(false);
    }

    const downloadTemplate = async() => {
        setOpen(true);
        const response = await ArriendosService.templateOnBoarding( rutEmpresa);
        if (!!response?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })

            const a = document.createElement('a');
            a.href = response?.data;
            a.download = 'Plantilla_arriendos.xlsx'; 
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
    }
    
    const confirmatedArriendos = async () => {
        setOpen(true);
        const response = await ArriendosService.confirmOnBoarding(dataProcess);
        console.log(response)
        if (!!response?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })

           //acciones

        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
    }

    return (
        <>
            <Protected>
                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <SpinnerGrow />
                        <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                    </Grid>
                </Backdrop>
                <div>
                    <Container maxWidth='xl'>
                        <Grid container justifyContent={"space-between"} spacing={3}>
                            <Grid item xs={5} container alignItems="center">
                                <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }} >Proceso de Onboarding</Typography>
                            </Grid>
                        </Grid>
                        <Grid container my={5} bgcolor={'#E9E7E3'} py={5} px={5}>
                            <Grid item xs={12}>
                                <Typography variant="h5" textAlign={"left"}>Cargar Arriendos Vigentes</Typography>
                            </Grid>
                            <Tooltip title={'Descargar plantilla'}>
                            <Button
                                component="label" variant="contained" 
                                sx={{ mt: "10px", mr: "5px"}}
                                className="btn-dwtemplate"
                                onClick={() => downloadTemplate() }
                            >
                                <SimCardDownloadIcon fontSize='small' sx={{color: 'black'}}/>
                            </Button>
                            </Tooltip>
                            <Tooltip title={'Importar'}>
                            <Button
                                component="label" variant="contained" 
                                sx={{ mt: "10px", mr: "5px"}}
                                className="btn-import"
                                startIcon={<CloudUploadIcon sx={{color:'red'}}/>}
                            >
                                <img src={excelImage} alt="Icon" width={20} height={20} />
                                <input
                                            ref={fileInputRef}
                                            id="doc_excel"
                                            type="file"
                                            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            name="doc_excel"
                                            style={{ display: 'none' }}
                                            onChange={(event) => {
                                                setValueDoc(event.target.files[0]);
                                                console.log(event.target.files[0]);
                                            }}
                                        />
                            </Button>
                            </Tooltip>
                        </Grid>
                        {
                            !!!dataProcess && 
                            <Typography>Sin data a mostrar</Typography>
                        }
                        {
                            !!dataProcess && 
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" mb={2}>Previsualización de los arriendos</Typography>
                                </Grid>
                                <Button  variant='contained' onClick={()=> confirmatedArriendos()} >Confirmar y Procesar Arriendos</Button>

                                    {/* <TableContainer component={Paper} elevation={3} style={{ borderRadius: '10px', overflow: 'hidden' }}>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                                                <TableRow>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }} >Código</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>RUT cliente</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Cliente</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>N° factura</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Fecha</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Cantidad</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Código producto</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Producto</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Garantia producto cantidad</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Total Garantia</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Código de pago garantia</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Posible devolución</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>N° última factura</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Fecha última factura</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataProcess.map((arriendo, key) => (
                                                    <TableRow key={key}>
                                                        <TableCell align="center">{arriendo.cod_arriendo}</TableCell>
                                                        <TableCell align="center">{arriendo.rut_cliente}</TableCell>
                                                        <TableCell align="center">{arriendo.cliente}</TableCell>
                                                        <TableCell align="center">{arriendo.n_factura}</TableCell>
                                                        <TableCell align="center">{arriendo.fecha_inicio}</TableCell>
                                                        <TableCell align="center">{arriendo.cantidad}</TableCell>
                                                        <TableCell align="center">{arriendo.codigo_producto}</TableCell>
                                                        <TableCell align="center">{arriendo.producto_arriendo}</TableCell>
                                                        <TableCell align="center">{arriendo.garantia_producto_x_cantidad}</TableCell>
                                                        <TableCell align="center">{arriendo.cod_garantia}</TableCell>
                                                        <TableCell align="center">{arriendo.total_garantia}</TableCell>
                                                        <TableCell align="center">{arriendo.posible_devolucion}</TableCell>
                                                        <TableCell align="center">{arriendo.n_ultima_factura}</TableCell>
                                                        <TableCell align="center">{arriendo.fecha_ultima_factura}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> */}

                                    <Grid container sx={{ mt: 1 }}>
                                        <TableData
                                            header={header}
                                            data={dataProcess ? dataProcess : []}
                                            total={Total ? Total : 0}
                                            disabledTextAction
                                        />
                                    </Grid>
                            </Grid>
                        }
                       
                    </Container>
                </div>
            </Protected>
        </>
    )
}


